.wrapper {
  display: flex;
  padding: 24px;
  width: 100%;
  min-height: 180px;

  & > .icon {
    width: 100px;
  }

  & > .alerts {
    flex: 1;

    & > .title {
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      // padding    : 30px;
    }

    & > .content {
      white-space: pre-line;
      width: 100%;
      // padding    : 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    & > .btn_wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid lightgrey;
      padding-top: 24px;

      & > div {
        width: 130px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
        color: white;
        background-color: var(--color-primary);
      }
    }
    .cancel-btn{
      margin-right: 10px;
      background-color: var(--color-error) !important;
    }
  }
}
