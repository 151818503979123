.payment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
// .title {
//   width: 100%;
//   text-align: center;
//   font-weight: 600;
//   font-size: 40px;
//   margin-top: 40px;
//   i {
//     &:first-child {
//       color: var(--color-sub-error) !important;
//       span {
//         color: var(--color-sub-error) !important;
//       }
//     }
//     &:last-child {
//       margin-left: 6px;
//       color: var(--color-primary);
//       span {
//         color: var(--color-primary);
//       }
//     }
//   }
// }

.subtitle {
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  font-size: 18px;
}

.day-select-buttons {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 10px;
    border-radius: 25px;
    background-color: var(--color-gray3);
    color: var(--color-primary);
    font-weight: 500;
    font-size: 15px;
    height: 45px;
    &:hover {
      background-color: var(--color-sub);
      color: var(--color-gray3);
    }
  }
  button.active {
    background-color: var(--color-primary);
    color: #fff;
  }
}
.origin_price {
  display: flex;
  font-size: 16px;
  margin-left: 8px;
  color: var(--color-gray6);
  text-decoration: line-through;
  align-items: center;
}
.item-select-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 44px;
  & > div {
    margin-top: 30px;
    width: 350px;
    border-radius: 14px;
    padding: 80px 35px 35px 35px;
    border: 1px solid var(--color-border);
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 0.25s;
    cursor: pointer;
    background-color: var(--color-gray4);
    &:hover {
      background-color: var(--color-gray2);
    }
    .tag {
      position: absolute;
      width: 150px;
      right: 20px;
      top: -18px;
      .tag-inner {
        // width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 11px;
        padding-left: 12px;
        & > .price {
          font-size: 33px;
          font-weight: bold;
          color: var(--color-gray7);
          margin-top: 0;
          z-index: 1;
          text-align: center;
        }
        & > .discount_percent {
          font-size: 15px;
          font-weight: bold;
          color: var(--color-primary);
          margin-top: 0;
          z-index: 1;
          text-align: center;
          line-height: 1em;
        }
        & > .period {
          text-align: center;
          position: relative;
          z-index: 2;
          font-size: 13px;
          color: var(--color-gray6);
        }
        img {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    span {
      width: 100%;
      text-align: left;
    }
    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .subtitle {
      margin-top: 20px;
      font-size: 16px;
      color: var(--color-primary);
      font-weight: bold;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        font-size: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        div {
          display: flex;
          flex-direction: row;

          &:first-child {
            width: 50px;
            svg {
              path {
                color: var(--color-primary);
              }
            }
          }
          &:last-child {
            font-size: 15px;
            color: var(--color-gray7);
            line-height: 1.67;
          }
        }
      }
    }
    .select-button {
      height: 30px;
      border-radius: 15px;
      margin-top: 24px;
      background-color: var(--color-gray3);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & > .emoji {
          font-size: 20px;
          width: 29px;
        }
        & > .text {
          font-size: 13px;
          width: 50px;
          white-space: nowrap;
          color: var(--color-gray6);
        }
      }
    }
    .select-button.active {
      background-color: var(--color-primary);
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & > .emoji {
          font-size: 20px;
          width: 29px;
        }
        & > .text {
          font-size: 13px;
          width: 50px;
          white-space: nowrap;
          color: #fff;
        }
      }
    }
  }
  div.active {
    box-shadow: var(--shadow);
    background-color: var(--color-gray1);
    .tag {
      span {
        color: #fff !important;
      }
    }
  }
}

.payment-category-wrap {
  width: 100%;
  background-color: var(--color-gray3);
  display: flex;
  justify-content: center;
  padding: 80px 0;
  margin-top: 70px;
  .main-wrap {
    position: relative;
    i {
      position: absolute;
      right: 0;
      bottom: -50px;
      font-size: 13px;
      color: var(--color-gray5);
    }
  }
  .payment-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    div {
      &:first-child {
        display: flex;
        flex-direction: column;
        span {
          text-align: left;
        }
        .title {
          font-size: 40px;
        }
        .subtitle {
          margin-top: 30px;
          line-height: 1.4;
          color: var(--color-gray7);
          font-size: 15px;
        }
        button {
          margin: 34px 0;
          width: auto;
          width: 120px;
        }
        .use-coupon {
          z-index: 2;
        }
        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          .before {
            font-size: 24px;
            font-weight: bold;
            color: var(--color-gray5);
            position: relative;
            img {
              z-index: 0;
              position: absolute;
              min-width: 115px;
              width: 150%;
              left: 70%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .after {
            font-size: 40px;
            font-weight: bold;
            color: var(--color-primary);
            margin-left: 60px;
          }
        }
      }
      &:last-child {
        .card-wrap {
          cursor: pointer;
          width: 440px;
          height: 85px;
          padding: 25px 30px;
          border-radius: 20px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          transition: 0.25s;
          margin-bottom: 20px;
          overflow: hidden;
          &:last-child {
            margin-bottom: 0;
          }
          .column {
            width: 100%;
            border-top: 1px solid var(--color-border);
            padding-top: 24px;
            margin-top: 24px;
            visibility: hidden;
            opacity: 0;
            transition: 0.15s;
            span {
              line-height: 1.67;
              font-size: 15px;
              color: #fff;
            }
            .last {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              button {
                margin: 0;
                margin-top: 10px;
                width: 100px;
                &:hover {
                  background-color: #fff;
                  color: var(--color-primary);
                }
              }
            }
          }
          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .title-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              .title {
                font-size: 20px;
                margin-left: 20px;
                display: block;
                font-weight: bold;
                position: relative;
                top: 2px;
                margin-top: 0;
                transition: 0.15s;
              }
            }
            .arrow-button {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: var(--color-gray3);
              border-radius: 50%;
              transition: 0.15s;
              svg {
                position: relative;
                top: 1px;
              }
            }
          }
        }
        .card-wrap.active {
          background-color: var(--color-primary);
          height: 234px;
          .column {
            visibility: visible;
            opacity: 1;
          }
          .title-row {
            svg {
              path {
                color: #fff;
              }
            }
            .title {
              color: #fff;
            }
          }
          .arrow-button {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.payment-solution-wrap {
  width: 100%;
  background-color: #fff;
  padding: 80px 0;
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > div {
      &:first-child {
        width: 625px;
        display: flex;
        flex-direction: column;
        .graph-label-wrap {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-top: 15px;
          span {
            width: 252px;
            text-align: center;
            font-weight: bold;
            font-size: 15px;
          }
        }
        .graph-wrap {
          width: 100%;
          border-bottom: 2px solid var(--color-gray8);
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          & > div {
            width: 252px;
            height: 374px;
            background-color: var(--color-primary);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .big {
              font-size: 66px;
              color: #fff;
              font-weight: bold;
            }
            .small {
              margin-bottom: 20px;
              font-size: 17px;
              color: #fff;
            }
            &:last-child {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              background-color: #fff;
              position: relative;
              .graph-txt {
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                .big {
                  font-size: 66px;
                  color: var(--color-primary);
                  font-weight: bold;
                }
                .small {
                  margin-bottom: 20px;
                  font-size: 17px;
                  color: var(--color-primary);
                  text-align: center;
                }
              }
              img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
                animation-name: downUp;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
                animation-direction: alternate;
              }
              .box {
                width: 100%;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                height: 90px;
                background-color: var(--color-primary);
              }
            }
          }
        }
      }
      &:last-child {
        text-align: right;
        display: flex;
        flex-direction: column;
        .title {
          text-align: right;
          font-size: 38px;
          margin-top: 5px;
        }
        .subtitle {
          text-align: right;
          line-height: 1.4;
          margin-top: 50px;
          font-size: 16px;
        }
      }
    }
  }
}
.payment-review-wrap {
  width: 100%;
  background-color: var(--color-primary);
  .main-wrap {
    padding: 80px 0;
    .title {
      font-size: 42px;
      font-weight: bold;
      color: #fff;
    }
    .subtitle {
      font-size: 20px;
      margin-top: 10px;
      color: #fff;
    }
    .review-content {
      width: 1100px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      border-radius: 5px;
      overflow: hidden;
      background-color: #fff;
      margin-top: 50px;
      .review-content-item {
        width: 100%;
        padding: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid var(--color-border);
        &:last-child {
          border: none;
        }
        & > div {
          &:first-child {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &:last-child {
            flex: 1;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            .title {
              font-size: 17px;
              font-weight: bold;
              margin: 0;
              text-align: left;
              color: var(--color-gray9);
            }
            .content {
              margin-top: 16px;
              font-size: 15px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}

@keyframes downUp {
  from {
    top: 30px;
  }
  to {
    top: 0;
  }
}

//legacy

.payment-complete-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  .payment-complete-title {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #595959;
  }
  .payment-complete-sub-title {
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }
}
.payment-complete-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 2px solid var(--color-primary);
  .row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    width: 100%;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 14px;
      span {
        width: 100%;
        text-align: left;
        font-size: 16px;
        line-height: 1.65em;
        color: #666;
        b {
          color: #666;
        }
        i {
          font-style: normal;
          margin: 0 10px;
        }
      }
      &:first-child {
        width: 200px;
        flex: none;
        span {
          font-size: 18px;
          color: #666;
          font-weight: bold;
        }
      }
    }
  }
}
.payment-complete-buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    width: 200px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid var(--color-primary);
    background-color: #fff;
    color: #666;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin: 0 14px;
    transition: 0.25s;
    &:hover {
      color: var(--color-primary);
    }
  }
}
