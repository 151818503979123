.beginSafetyContainer {
  font-size: 11px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 1.7cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
    .save_container {
      display: none;
      color: white;
      span {
        display: none;
      }
    }
  }
  .save_container {
    display: flex;
    justify-content: center;
    width: 100%;

    .square_btn {
      width: 200px;
      height: 40px;
      font-size: 13px;
      margin-top: 20px;
      line-height: 100%;
      background-color: var(--color-primary);
      color: white;
      font-weight: 600;
      min-width: 0;
      padding: 0;
      span {
        color: white;
      }
    }
  }
  .title {
    font-size: 30px;
    text-align: center;
  }

  .container_body {
    border: 1px solid;
    border-bottom: 0px;

    .table_header {
      display: flex;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid;

      .item_name {
        width: 44%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_name {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_price {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_note {
        width: 22%;
        font-weight: 600;
      }
    }

    .envi_plan_items {
      display: flex;
      width: 100%;
      border-bottom: 1px solid;

      .item_name {
        display: flex;
        width: 291.8 * 0.4px;
        padding-left: 3px;
        border-right: 1px solid;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid;

        .item_num {
          width: 10%;
        }

        .item_num_name {
          width: 90%;
        }
      }

      .envi_items_container {
        width: 291.8 * 0.6px + 112.73px + 112.73px + 145.89px;

        .envi_items {
          display: flex;
          width: 100%;
          border-bottom: 1px solid;
          height: 17.7px;

          // 인쇄하기 했을 때 칸 안맞는거 보정 + 1px
          .envi_sub_item {
            width: 291.8 * 0.6px + 1px;
            border-right: 1px solid;
            padding-left: 5px;
            align-self: center;
            height: 100%;
          }

          // 인쇄하기 했을 때 칸 안맞는거 보정 - 1px
          .product_name {
            width: 112.73px - 1px;
            border-right: 1px solid;
            text-align: center;
            padding: 1px;
            align-self: center;

            .product_name_input {
              width: 100%;
            }
          }

          .product_price {
            width: 112.73px;
            border-right: 1px solid;
            padding: 1px;
            align-self: center;

            .product_price_input {
              width: 100%;
              text-align: right;
              padding-right: 5px;
            }
          }

          .total_price {
            width: 112.73px;
            border-right: 1px solid;
            text-align: right;
            padding-right: 8px;
          }

          .note {
            width: 145.89px;
            text-align: center;
            padding: 1px;
            align-self: center;

            .note_input {
              width: 100%;
            }
          }
        }
      }
    }
  }

  input {
    outline: none;
    height: 15px;
    text-align: center;
    border: hidden;
  }
}
