/* body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

[class~="page"]:not(.container):not(.mypage-container) {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 0 auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
  padding: 1cm;
  height: 256mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

table:not([class]) {
  width: 100%;
  height: 100%;
  border: 1px solid #333333;
  border-collapse: collapse;
}

table:not([class]) th:not([class]),
table:not([class]) td:not([class]) {
  border: 1px solid #333333;
}

/* .title {
  font-size: 50px;
  font-weight: bold;
} */

.nonLine {
  border: 0px solid white;
}

.back-top > svg > path {
  color: rgb(68, 132, 255);
}

.printer-icon > svg > path {
  color: #ffffff;
}

.head-three-string {
  width: 100px;
  text-align: justify;
  text-align-last: justify;
  display: inline-block;
  text-justify: inter-cluster;
}
