.wrapper {
  width: 100%;
  min-height: 100%;

  & > div {
    width: 1200px;
    height: 100%;
  }

  .title {
    width: 100%;
    font-size: 33px;
  }
  .subtitle {
    width: 100%;
    font-size: 13px;
    color: var(--color-gray5);
    margin-top: 10px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 2px solid var(--color-video-primary);
    margin-top: 40px;
    .content-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > div {
        flex: 1;
        &:first-child {
          display: flex;
          flex-direction: column;
          padding: 26px;
          .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 20px 0;
            border-bottom: 1px solid var(--color-border);
            .user-name {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              .profile {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background-color: var(--color-video-primary);
                display: flex;
                justify-content: center;
                align-items: center;
                path {
                  color: #fff;
                }
              }
              .profile-name {
                font-size: 24px;
                font-weight: bold;
                margin: 0 10px;
              }
              .sir {
                font-size: 14px;
                color: var(--color-gray6);
              }
            }
            .row-title {
              font-size: 16px;
              font-weight: bold;
              width: 80px;
            }
            .row-content {
              flex: 1;
              color: var(--color-gray6);
              font-size: 14px;
            }
            .row-highlight {
              font-size: 14px;
              color: var(--color-video-primary);
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          padding: 26px;
          .used {
            user-select: none;
            width: 100%;
            padding: 32px;
            background-color: var(--color-video-primary);
            border-radius: 4px;
            .used-title {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .title {
                font-size: 20px;
                color: #fff;
                margin: 0;
                text-align: left;
              }
              .used-arrow-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                .hr {
                  width: 1px;
                  height: 14px;
                  background-color: #fff !important;
                  padding: 0;
                  margin: 0 4px;
                  cursor: default !important;
                }
                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  padding: 6px;
                  transition: 0.15s;
                  border-radius: 4px;
                  cursor: pointer;
                  &:hover {
                    background-color: var(--color-sub);
                  }
                  span {
                    font-size: 14px;
                  }
                  path {
                    color: #fff;
                  }
                  &:last-child {
                    border: none;
                  }
                }
              }
            }
            .used-content {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin-top: 30px;
              padding-top: 30px;
              border-top: 1px solid #2936be;
              .used-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 14px 0;
                .used-row-title {
                  width: 90px;
                  color: #fff;
                  font-weight: bold;
                  font-size: 16px;
                }
                .used-row-content {
                  font-size: 16px;
                  color: #fff;
                  span {
                    color: #fff;
                  }
                }
              }
            }
            .button-rev-row {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              button {
                background-color: var(--color-video-sub);
              }
            }
          }
          .used.other_statement {
            background-color: #7b84ed;
          }
          .used.estimate {
            background-color: #171d69;
          }
        }
      }
    }
    .content-title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 70px;
      margin-bottom: 16px;
      .coupon-wrapper {
        display: flex;
        align-items: center;
      }
      .content-title {
        font-size: 24px;
        font-weight: bold;
      }
      .add-coupon {
        margin-left: 10px;
        font-size: 13px;
      }
      .content-add-coupone {
        font-size: 14px;
        cursor: pointer;
        color: var(--color-gray6);
      }
      .content-title-more {
        display: flex;
        height: 36px;
        align-items: flex-end;
        font-size: 15px;
        color: #161d24;
        cursor: pointer;
      }
    }
    .content-title-row.more {
      align-items: flex-end;
    }
    .mypage-content-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--color-border);
      .mypage-content-none-tr {
        width: 100%;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 13px;
          color: var(--color-gray6);
        }
      }
      .mypage-content-tr {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .th {
          padding: 16px 0;
          font-size: 14px;
          font-weight: bold;
          border-top: 2px solid var(--color-border);
          background-color: var(--color-gray3);
          text-align: center;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            width: 250px;
          }
          &:nth-child(4) {
            width: 250px;
          }
        }
        .td {
          padding: 16px 0;
          font-size: 13px;
          border-top: 1px solid var(--color-border);
          text-align: center;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            width: 250px;
          }
          &:nth-child(4) {
            width: 250px;
          }
        }
      }
    }
    .mypage-payment-content-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--color-border);
      .mypage-content-none-tr {
        width: 100%;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 13px;
          color: var(--color-gray6);
        }
      }
      .mypage-content-tr {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        .th {
          padding: 16px 0;
          font-size: 14px;
          font-weight: bold;
          border-top: 2px solid var(--color-border);
          background-color: var(--color-gray3);
          text-align: center;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            width: 250px;
          }
          &:nth-child(3) {
            flex: 1;
          }
          &:nth-child(4) {
            width: 200px;
          }
          &:nth-child(5) {
            width: 150px;
          }
          &:nth-child(6) {
            width: 100px;
          }
          &:nth-child(7) {
            width: 100px;
          }
        }
        .td {
          padding: 16px 0;
          font-size: 13px;
          border-top: 1px solid var(--color-border);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 52px;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          &:nth-child(3) {
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
          &:nth-child(4) {
            width: 200px;
            display: flex;
            align-items: center;
          }
          &:nth-child(5) {
            width: 150px;
          }
          &:nth-child(6) {
            width: 100px;
          }
          &:nth-child(7) {
            width: 100px;
          }
          span {
            margin: 3px;
            color: var(--color-video-primary);
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
    .re-pw {
      width: 100px;
      margin-top: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  
  .ant-spin {
    .ant-spin-dot {
      font-size: 24px;
    }
    
    &.ant-spin-large .ant-spin-dot {
      font-size: 32px;
    }
  }
}
