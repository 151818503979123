.lectureList_wrapper {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  flex-direction: column;
  .lectureList_tabs {
    width: 1200px;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      margin: 20px;
      li {
        margin: 10px;
        display: inline;
        cursor: pointer;
        &:hover {
          color: red;
        }
        &.checked {
          color: var(--color-video-primary);
          font-weight: bold;
        }
      }
    }
  }
  .lectureLists_container {
    width: 1200px;
    margin-top: 30px;
    background-color: white;
    .contents_container {
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      &:nth-child(1) {
        padding-top: 0px;
      }
      &:last-child {
        margin-bottom: 100px;
      }
      .contentImg_container {
        width: 30%;
        height: 200px;
        margin-right: 30px;
        border-radius: 10px;
        box-shadow: 0 3px 10px rgba(44, 44, 44, 0.2);
        transition-duration: 0.5s;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        &:hover {
          transform: scale(0.98);
          transition: all 0.5s ease-in-out;
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;
          transition-delay: 0s;
          transition-property: all;
        }
      }
      .contentInfo_container {
        width: 850px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title_container {
          .content_title {
            text-decoration: none;
            color: #105796;
            font-size: 19px;
            font-weight: bold;
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
          p {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
        .content_status {
          width: 130px;
          margin: 10px 10px 10px 0;
          background: 0 0;
          border: 1px solid #ff4949;
          color: #ff4949;
          font-size: 16px;
          transition: all 0.3s;
          z-index: 1;
          float: right;
          &:hover {
            background-color: #ff4949;
            color: white;
          }
        }
        .amount_container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .bottom_container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .service {
              color: var(--color-video-primary);
            }
            .amount_wrapper {
              display: flex;
              .origin_amount {
                width: 130px;
                margin: 10px 0;
                float: right;
                text-align: right;
                color: #999;
                font-size: 18px;
                text-decoration: line-through;
              }
              .content_amount {
                width: 130px;
                margin: 10px 10px 10px 0;
                float: right;
                text-align: center;
                color: #ff4949;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .blank {
      text-align: center;
    }
  }
}
