.app_container {
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_container {
    width: 500px;
    margin: 0 auto;
    padding: 100px;
    border: 1px solid lightgrey;
    margin-top: 100px;
    .text_label {
      font-size: 12px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .des {
      margin-top: 30px;
      color: rgb(158, 158, 158);
      margin-bottom: 30px;
    }
    input {
      width: 100%;
      outline: none;
      background-color: rgb(244, 244, 244);
      height: 40px;
      border: 1px solid lightgrey;
      border-radius: 5px;
      font-size: 14px;
      padding-left: 10px;
    }
    & > div > button,
    & > div > * > button {
      width: 100%;
      height: 38px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      color: white;
    }
    .signUp {
      color: rgb(64, 64, 64);
      border: 2px solid var(--color-video-primary);
      background-color: white !important;
    }
    .forget {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .forget > span {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
