.cost-wrapper {
  width: 100%;
  margin: 0 auto;
  .cost-project-title {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .cost-project-title-column {
      display: flex;
      flex-direction: column;
      .bodytitle {
        font-size: 30px;
        font-weight: bold;
        position: relative;
        & > .lecture {
          width: 135px;
          height: 36px;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          left: 180px;
          right: 0px;
          top: 5px;
          background-color: #172a88;
          border-radius: 18px;
          border: 1px solid;
          font-weight: normal;
        }
      }
      .body-subtitle {
        font-size: 13px;
        color: var(--color-gray5);
        margin-top: 10px;
      }
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .lecture {
      width: 135px;
      height: 36px;
      margin-top: 5px;
      font-size: 14px;
      background-color: #172a88;
      border-radius: 18px;
      border: 1px solid;
      font-weight: normal;
    }
  }
  .info {
    display: block;
    width: 24px;
    margin-right: 3px;
  }
  .menual {
    color: var(--color-primary);
    cursor: pointer;
    font-size: 16px;
  }
  .action-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 65px;
    & > div:first-child {
      width: 223px;
      display: flex;
      height: 36px;
      & > input {
        width: 177px;
        height: 100%;
        border-radius: 13px 0 0 13px !important;
        background-color: white;
        outline: none;
        border: 1px solid lightgrey;
        padding-left: 5px;
      }
      & > div {
        width: 46px;
        height: 100%;
        background-color: var(--color-primary);
        border-radius: 0 13px 13px 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          color: white;
        }
      }
    }
    & > div:last-child {
      display: flex;
    }
  }
  .mainCircleBtn {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    box-shadow: rgb(224, 224, 224) 1px 1px 3px 0px;
    border: 1px solid rgb(224, 224, 224);
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      background-color: var(--color-background-light);
    }
    & > div:first-child {
      width: 100%;
      height: 55%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      & > span {
        path {
          color: var(--color-primary);
        }

        font-size: 30px;
        position: relative;
        top: 5px;
      }
    }

    & > div:last-child {
      width: 100%;
      height: 45%;
      padding-top: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-weight: bold;
    }
  }
  .cell-wrapper {
    width: 100%;
    float: left;
    min-height: 530px;
    .cell {
      width: 24%;
      height: 245px;
      margin-right: 1.33%;
      float: left;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.2s;
      position: relative;
      box-sizing: border-box;
      border-top: 3px solid var(--color-primary);
      &:nth-child(4n + 0) {
        margin-right: 0;
      }
      .bookmark {
        position: absolute;
        top: 0px;
        left: 0px;
        svg,
        path {
          color: rgb(241, 196, 15);
        }
        span {
          position: relative;
          top: -7px;
          font-weight: bold;
          color: rgb(230, 126, 34);
        }
      }
      .delete {
        position: absolute;
        top: 10px;
        right: 3px;
        cursor: pointer;
        svg,
        path {
          color: var(--color-error);
        }
      }
      .edit {
        position: absolute;
        top: 10px;
        right: 30px;
        cursor: pointer;
        svg,
        path {
          color: var(--color-primary);
        }
      }
      &:hover {
        background-color: var(--color-background-light);
      }
      & > .projectTitle {
        flex: 1;
        & > div:first-child {
          padding: 8px 10px;
          width: 100%;
          display: flex;
          i {
            font-style: normal;
            font-size: 11px;
            width: 20%;
            margin-right: 1%;
            padding: 4px 0;
            text-align: center;
            border-radius: 25px;
            background-color: var(--color-primary);
            box-sizing: border-box;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(1) {
              background-color: #fff;
              color: var(--color-primary);
              border: 2px solid var(--color-primary);
              font-weight: bold;
            }
            &:nth-child(2) {
              background-color: var(--color-primary);
            }
            &:nth-child(3) {
              background-color: var(--color-error);
            }
            &:nth-child(4) {
              background-color: var(--color-primary);
            }
            &:nth-child(5) {
              background-color: var(--color-error);
              margin-right: 0;
            }
          }
          & > div:first-child {
            display: flex;
            align-items: center;
            & > span {
              color: rgb(230, 126, 34);
              font-weight: bold;
            }
          }
        }
        & > div:last-child {
          // flex: 1;
          padding: 10px 30px 0 30px;
          font-weight: bold;
          font-size: 18px;
          color: #404040;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          width: 100%;
          height: 75px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      & > div:last-child {
        padding: 30px;
        div {
          font-size: 14px;
          color: #404040;
        }
      }
    }
    .cell:nth-child(4n) {
      margin-right: 0;
    }
  }
}
.type-card {
  box-shadow: rgb(190, 190, 190) 1px 1px 3px 0px;
  width: 65px;
  padding: 9px 0;
  background-color: white;
  height: calc(100% - 20px);
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid var(--color-primary);
  transition: 0.25s;
  &:hover {
    background-color: var(--color-background-light);
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      path {
        color: var(--color-primary);
      }
      font-size: 30px;
      position: relative;
      top: 5px;
    }

    & > img {
      width: 40%;
    }
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    font-size: 11px;
    color: var(--color-font);
    margin-top: 10px;
    word-break: keep-all;
  }
}
.projectTypeNone {
  background-color: #e0e0e0 !important;
}

.openFile {
  position: relative;
}

.cell-table {
  width: 100%;
  background-color: var(--color-gray3);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .cell-table-tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.15s;
    &:hover {
      transform: scale(1.01, 1.04);
    }
    &:first-child {
      border: none;
      margin-bottom: 0;
      cursor: default;
      transform: scale(1, 1) !important;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .cell-table-th {
      padding: 18px 8px;
      font-size: 14px;
      color: var(--color-gray6);

      &:first-child {
        width: 50px;
        text-align: center;
      }
      &:nth-child(2) {
        padding-left: 20px;
        flex: 1;
      }
      &:nth-child(3) {
        width: 230px;
      }
      &:nth-child(4) {
        width: 220px;
      }
      &:nth-child(5) {
        width: 220px;
      }
    }
    .cell-table-btns-td {
      padding: 15.5px 8px;
      font-size: 14px;
      color: var(--color-gray6);
      background-color: var(--color-gray1);
      width: 220px;
      button {
        margin-right: 10px;
        min-width: 60px !important;
        &:last-child {
          background-color: var(--color-sub-error);
        }
      }
    }
    .cell-table-td {
      padding: 20px 8px;
      font-size: 14px;
      color: var(--color-gray6);
      background-color: var(--color-gray1);
      height: 63px;
      display: flex;
      align-items: center;
      .cell-tags-wrap {
        float: left;
        i {
          background-color: var(--color-primary);
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 11px;
          color: #fff;
          text-align: center;
          float: left;
          margin-right: 4px;
          border: none;
          &:first-child {
            background-color: #fff;
            border: 1px solid var(--color-primary);
            color: var(--color-primary);
          }
          &:nth-child(3) {
            background-color: var(--color-sub-error);
          }
          &:nth-child(5) {
            background-color: var(--color-sub-error);
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &:first-child {
        width: 50px;
        text-align: center;
      }
      &:nth-child(2) {
        padding-left: 20px;
        flex: 1;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important;
        overflow: hidden !important;
      }
      &:nth-child(3) {
        width: 230px;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important;
        overflow: hidden !important;
      }
      &:nth-child(4) {
        width: 220px;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important;
        overflow: hidden !important;
      }
      &:nth-child(5) {
        width: 220px;
      }
    }
  }
}

.favoriteButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.favoriteButton:hover {
  transform: scale(1.1);
}

.starIcon {
  transition: all 0.3s ease;
}

.starIcon.filled {
  fill: var(--bs-warning);
}

.starIcon.outline {
  stroke: var(--bs-warning);
  fill: none;
}

.favoriteButton:hover .starIcon.outline {
  fill: var(--bs-warning);
}