.aiPostForm {
  max-width: 600px;
  margin: 0 auto;

  :global {
    .ant-form-item-label > label {
      font-weight: bold;
    }

    .ant-input, .ant-input-textarea {
      border-radius: 4px;
    }

    .ant-btn-primary {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }
  }
}
