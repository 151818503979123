.lecture_container {
  position: relative;
  .iframe_wrapper {
    width: 745px;
    height: 420px;
    z-index: 1;
    * {
      z-index: 1;
    }
    border-radius: 6px;
    overflow: hidden;
    html {
      width: 100%;
      height: 100%;
    }
  }
  .shield {
    width: 227px;
    height: 64px;
    position: absolute;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(20px);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
