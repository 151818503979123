.project-typer-wrapper {
  width: 100%;
  display: flex;
  padding: 16px 0;
  border-top: 1px solid #e0e0e0;
  flex-direction: column;
  .project-typer-title {
    font-size: 16px;
    font-weight: 700;
  }
}

.project-typer-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  .project-typer-list-message {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: #595959;
    margin-top: 14px;
  }
  .project-typer-list-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    background-color: #f4f4f4;
    cursor: pointer;
    .project-title {
      font-size: 14px;
    }
  }
}
