.notice-wrapper {
  width: 100%;
  min-height: 100%;

  & > div {
    width: 1200px;
    height: 100%;
  }
}

.notice-title {
  width: 100%;
  padding: 40px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 32px;
  font-weight: 700;
  color: #333333;
  user-select: none;
}

.notice-content {
  width: 100%;
  display: flex;

  div {
    overflow: hidden;
  }
}

.notice-table-wrapper {
  width: 100%;
}

.search-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.notice-row {
  width: 100%;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  transition: 0.25s;

  &:hover {
    background-color: #f1f1f1;
  }

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    overflow: hidden !important;
    box-sizing: border-box;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    padding: 14px 0;
  }

  & > div:nth-child(1) {
    width: 10% !important;
    justify-content: flex-start !important;
  }

  & > div:nth-child(2) {
    width: 70% !important;
    text-align: left;
    padding-left: 50px;
  }

  & > div:nth-child(3) {
    width: 20% !important;
  }

  & > div:nth-child(4) {
    width: 20% !important;
  }
}

.notice-header {
  background-color: rgb(243, 243, 243);
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  font-size: 14px;
  & > div {
    font-weight: bold;
    justify-content: center;
    text-align: center;
    padding: 10px 0;
  }

  & > div:nth-child(1) {
    width: 10%;
    text-align: center;
  }

  & > div:nth-child(2) {
    width: 70%;
    text-align: center;
  }

  & > div:nth-child(3) {
    width: 20%;
  }

  & > div:nth-child(4) {
    width: 20%;
  }
}

.notice-search > .ant-input-wrapper > .ant-input-group-addon > .ant-input-search-button {
  height: 38px !important;
  border-radius: 0 13px 13px 0 !important;
  background-color: var(--color-primary);
  min-width: 0;
}

.notice-search > span > span > button > span > svg > path {
  color: #ffffff;
}

#pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:30px;
}