.resizableContainer {
  overflow: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.postFormContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.postForm {
  width: 100%;

  :global {
    .ant-form-item {
      margin-bottom: 24px;
    }

    .quill {
      .ql-container {
        height: 250px;
      }
    }
  }
}

.quillEditor {
  :global {
    .ql-container {
      font-size: 16px;
      height: 250px;
    }

    .ql-editor {
      min-height: 200px;
    }
  }
}

.uploadContainer {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 10px;
}

.uploader {
  :global {
    .ant-upload-list {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.submitItem {
  margin-top: 20px;
  text-align: right;
}

.editorContainer {
  position: relative;
  margin-bottom: 20px;

  :global {
    .react-resizable {
      position: relative;
    }

    .react-resizable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      background-color: #1890ff;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;
      border-radius: 50%;
      
      &::before {
        content: '';
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 6px 6px;
        border-color: transparent transparent #ffffff transparent;
      }
    }

    .ql-container {
      font-size: 16px;
    }

    .ql-editor {
      min-height: 200px;
    }
  }
}