.ai-statement-content {
  width: 100%;
  overflow-x: auto;
}
.ai-statement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .ai-statement-title {
    font-size: 16px;
    font-weight: 600;
    b {
      color: var(--color-primary);
    }
  }
  .ai-statement-header-button {
    display: flex;
    gap: 10px;
    span {
      font-size: 18px;
      cursor: pointer;
    }
  }
}
.ai-statement-footer {
  display: flex;
  gap: 10px;
  button {
    cursor: pointer;
  }
}

.ai-statement-description {
  margin-bottom: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #a8d6f5;
  padding: 14px;
  border-radius: 5px;
  .ai-statement-description-title {
    font-size: 16px;
    font-weight: 600;
  }
  .ai-statement-description-content {
    font-size: 14px;
    font-weight: 400;
  }
}
.ai-statement-citations {
  margin-top: 10px;
  .ai-statement-citations-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.citations-card-container {
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  .citation-card {
    flex: 1;
    min-width: 0;

    a {
      text-decoration: none;
      color: inherit;
      display: block;
      height: 100%;
      background: #f5f5f5;
      border-radius: 8px;
      padding: 16px;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
    }

    .citation-title {
      font-weight: 500;
      margin-bottom: 8px;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .citation-url {
      font-size: 12px;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
