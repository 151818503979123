.ai-statement {
  width: 100%;
  background-color: #fff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
  iframe {
    width: 1200px;
    min-height: 100px; // 최소 높이 설정
    height: 100%;
    border: none;
  }
}
