.findAccount_wrapper {
  width: 1000px;
  margin: 0 auto;
  user-select: none;
  .findbox_wrapper {
    width: 565px;
    height: 545px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    .inner_wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 340px;
      height: 395px;
      .accout_title_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .title_item {
          cursor: pointer;
          user-select: none;
          font-weight: bold;
          font-size: 17px;
          width: 170px;
          color: rgb(158, 158, 158);
          text-align: center;
          .under_line {
            margin-top: 7px;
            width: 100%;
            height: 5px !important;
            background-color: lightgrey;
            left: 0;
            bottom: 0;
            &.selected {
              background-color: var(--color-video-primary);
            }
          }
          &.selected {
            color: var(--color-video-primary);
          }
        }
      }
      .account_title_description {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 50px;
        color: rgb(158, 158, 158);
      }
      .user_info_wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        div {
          width: 85px;
          font-size: 15px;
          color: rgb(64, 64, 64);
        }
        input {
          padding: 10px;
          width: 260px;
          height: 36px;
          box-shadow: none;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(224, 224, 224);
          &.phoneNumber_input {
            width: 175px;
          }
        }
        button {
          cursor: pointer;
          height: 36px;
          padding: 3px;
          margin-left: 5px;
          font-size: 12px;
          color: white;
          text-align: center;
          border-radius: 3px;
          background-color: var(--color-video-sub);
          font-weight: bold;
        }
      }
      .certification_number_input {
        margin-left: 85px;
        margin-top: 20px;
        padding: 10px;
        width: 160px;
        height: 36px;
        box-shadow: none;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(224, 224, 224);
      }
      .find_account_button {
        height: 38px;
        width: 300px;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        font-size: 16px;
        color: white;
        text-align: center;
        border-radius: 6px;
        background-color: var(--color-video-sub);
        font-weight: bold;
      }
      .back_to_login {
        user-select: none;
        margin-top: 75px;
        margin-left: 21px;
        margin-bottom: 10px;
        font-size: 14px;
        color: rgb(52, 73, 94);
        font-weight: 700;
        span {
          cursor: pointer;
          text-decoration-line: underline;
          color: rgb(52, 152, 219);
        }
      }
      .founded_id {
        margin-top: 120px;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        user-select: text;
      }
      .founded_id_button_wrapper {
        display: flex;
        margin-top: 150px;
        flex-direction: row;
        justify-content: center;
        &>.login {
          height: 38px;
          width: 300px;
          margin-right: 10px;
          user-select: none;
          cursor: pointer;
          font-size: 16px;
          color: white;
          text-align: center;
          border-radius: 6px;
          background-color: var(--color-video-sub);
          font-weight: bold;
        }
      }
      .find_pw_wrapper {
        margin-top: 59px;
      }
      .pw_change_input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        div {
          width: 130px;
          font-size: 15px;
          color: rgb(64, 64, 64);
        }
        input {
          padding: 10px;
          width: 260px;
          height: 36px;
          box-shadow: none;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(224, 224, 224);
        }
      }
      .pw_change_button {
        height: 38px;
        width: 300px;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 160px;
        font-size: 16px;
        color: white;
        text-align: center;
        border-radius: 6px;
        background-color: var(--color-video-sub);
        font-weight: bold;
      }
    }
  }
}