.aiPostDetailContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.backButton {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #1890ff;
  }
}

.aiPostDetailCard {
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.postHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.postContent {
  margin: 20px 0;
  word-break: break-word;
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
  }
}

.postFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconButton {
  display: flex;
  align-items: center;
  
  &.liked {
    color: #1890ff;
  }
}

.aiAnswerCard {
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  background-color: #f0f8ff;
}

.aiAnswerFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .anticon, svg {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
}

.commentContent {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.likeIcon {
  color: #ff4d4f;
}

.aiAnswerContent {
  margin: 20px 0;
  word-break: break-word;
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
  }
}

.reactionLike {
  svg {
    color: #1890ff;
  }
}

.reactionDislike {
  svg {
    color: #ff4d4f;
  }
}

.citations {
  margin-top: 16px;
  
  .citationLinks {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .citationLink {
    color: #1890ff;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .aiPostDetailContainer {
    padding: 20px 10px;
  }

  .aiPostDetailCard,
  .aiAnswerCard {
    border-radius: 0;
    box-shadow: none;
  }
}
