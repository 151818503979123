.postCard {
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }
}

.cardLink {
  display: flex;
  height: 100%;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.postContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.postTitle {
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.authorDateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4px;
}

.author, .date {
  font-size: 12px;
  color: #8c8c8c;
}

.postStats {
  display: flex;
  align-items: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-size: 14px;
  color: #8c8c8c;
}

.likeIcon {
  color: #ff4d4f;
  margin-right: 4px;
}

.commentIcon {
  color: #1890ff;
  margin-right: 4px;
}
