.beginSafetyContainer {

  font-size: 12px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    max-height: 29.7cm;
    padding: 2cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
  }

  .title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .table_header {
    display: flex;
    width: 100%;
    text-align: center;
    border: 1px solid;

    .item_name {
      width: 25%;
      border-right: 1px solid;
      display: flex;
      justify-content: center;

      .item_num {
        width: 30%;
        align-self: center;
      }

      .item_num_name {
        width: 100%;
        align-self: center;
        word-break: keep-all;
        padding: 5px;
        text-align: left;
      }
    }

    .usage_date {
      width: 15%;
      border-right: 1px solid;

      .date_input {
        width: 100%;
      }

      .btn_container {
        width: 100%;
        height: 20px;

        .square_btn {
          width: 20px;
          height: 100%;
          line-height: 100%;
          background-color: var(--color-primary);
          color: white;
          font-weight: 600;
          min-width: 0;
          padding: 0;
        }
      }
    }

    .usage_plan_items {
      width: 45%;
      border-right: 1px solid;

      .plan_item_name_input {
        width: 100%;
      }

      .plan_item_num_input {
        width: 100%;
      }

      .plan_item_price_input {
        width: 100%;
      }
    }

    .usage_item_price {
      width: 15%;

      .item_price_input {
        width: 100%;
      }
    }
  }

  .input_box {
    height: 20px;
    padding: 1px;
  }

  .comment {
    padding-left: 5px;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }

  input {
    outline: none;
    height: 18px;
    text-align: center;
  }
}

.beginSafetyContainer {

  font-size: 11px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 1.7cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
  }

  .title {
    font-size: 30px;
    text-align: center;
  }

  .container_body {
    border: 1px solid;
    border-bottom: 0px;

    .table_header {
      display: flex;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid;

      .item_name {
        width: 44%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_name {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_price {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_note {
        width: 22%;
        font-weight: 600;
      }
    }

    .envi_plan_items {
      display: flex;
      width: 100%;
      border-bottom: 1px solid;

      .item_name {
        display: flex;
        width: 291.8*0.4px;
        padding-left: 3px;
        border-right: 1px solid;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid;

        .item_num {
          width: 10%;
        }

        .item_num_name {
          width: 90%;
        }
      }

      .envi_items_container {
        width: 291.8*0.6px + 112.73px + 112.73px + 145.89px;

        .envi_items {
          display: flex;
          width: 100%;
          border-bottom: 1px solid;
          height: 17.7px;


          // 인쇄하기 했을 때 칸 안맞는거 보정 + 1px
          .envi_sub_item {
            width: 291.8*0.6px + 1px;
            border-right: 1px solid;
            padding-left: 5px;
            align-self: center;
            height: 100%;
          }

          // 인쇄하기 했을 때 칸 안맞는거 보정 - 1px
          .product_name {
            width: 112.73px - 1px;
            border-right: 1px solid;
            text-align: center;
            padding: 1px;
            align-self: center;

            .product_name_input {
              width: 100%;
            }
          }

          .product_price {
            width: 112.73px;
            border-right: 1px solid;
            padding: 1px;
            align-self: center;

            .product_price_input {
              width: 100%;
              text-align: right;
              padding-right: 5px;
            }
          }

          .total_price {
            width: 112.73px;
            border-right: 1px solid;
            text-align: right;
            padding-right: 8px;
          }

          .note {
            width: 145.89px;
            text-align: center;
            padding: 1px;
            align-self: center;

            .note_input {
              width: 100%;
            }
          }
        }

      }
    }
  }

  input {
    outline: none;
    height: 15px;
    text-align: center;
  }
}

.completionSafetyContainer {

  font-size: 12px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    max-height:29.7cm;
    padding: 2cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
  }

  .title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .table_header {
    display: flex;
    width: 100%;
    text-align: center;
    border: 1px solid;

    .item_name {
      width: 25%;
      border-right: 1px solid;
      display: flex;
      justify-content: center;

      .item_num {
        width: 30%;
        align-self: center;
      }

      .item_num_name {
        width: 100%;
        align-self: center;
        word-break: keep-all;
        padding: 5px;
        text-align: left;
      }
    }

    .usage_date {
      width: 15%;
      border-right: 1px solid;

      .date_input {
        width: 100%;
      }

      .btn_container {
        width: 100%;
        height: 20px;

        .square_btn {
          width: 20px;
          height: 100%;
          line-height: 100%;
          background-color: var(--color-primary);
          color: white;
          font-weight: 600;
          min-width: 0;
          padding: 0;
        }
      }
    }

    .usage_plan_items {
      width: 45%;
      border-right: 1px solid;

      .plan_item_name_input {
        width: 100%;
      }

      .plan_item_num_input {
        width: 100%;
      }

      .plan_item_price_input {
        width: 100%;
      }
    }

    .usage_item_price {
      width: 15%;

      .item_price_input {
        width: 100%;
      }
    }
  }

  .input_box {
    height: 20px;
    padding: 1px;
  }

  .comment {
    padding-left: 5px;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }

  input {
    outline: none;
    height: 18px;
    text-align: center;
  }
}

.beginSafetyContainer {

  font-size: 11px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    max-height:29.7cm;
    padding: 1.7cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
  }

  .title {
    font-size: 30px;
    text-align: center;
  }

  .container_body {
    border: 1px solid;
    border-bottom: 0px;

    .table_header {
      display: flex;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid;

      .item_name {
        width: 44%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_name {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_price {
        width: 17%;
        border-right: 1px solid;
        font-weight: 600;
      }

      .envi_note {
        width: 22%;
        font-weight: 600;
      }
    }

    .envi_plan_items {
      display: flex;
      width: 100%;
      border-bottom: 1px solid;

      .item_name {
        display: flex;
        width: 291.8*0.4px;
        padding-left: 3px;
        border-right: 1px solid;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid;

        .item_num {
          width: 10%;
        }

        .item_num_name {
          width: 90%;
        }
      }

      .envi_items_container {
        width: 291.8*0.6px + 112.73px + 112.73px + 145.89px;

        .envi_items {
          display: flex;
          width: 100%;
          border-bottom: 1px solid;
          height: 17.7px;


          // 인쇄하기 했을 때 칸 안맞는거 보정 + 1px
          .envi_sub_item {
            width: 291.8*0.6px + 1px;
            border-right: 1px solid;
            padding-left: 5px;
            align-self: center;
            height: 100%;
          }

          // 인쇄하기 했을 때 칸 안맞는거 보정 - 1px
          .product_name {
            width: 112.73px - 1px;
            border-right: 1px solid;
            text-align: center;
            padding: 1px;
            align-self: center;

            .product_name_input {
              width: 100%;
            }
          }

          .product_price {
            width: 112.73px;
            border-right: 1px solid;
            padding: 1px;
            align-self: center;

            .product_price_input {
              width: 100%;
              text-align: right;
              padding-right: 5px;
            }
          }

          .total_price {
            width: 112.73px;
            border-right: 1px solid;
            text-align: right;
            padding-right: 8px;
          }

          .note {
            width: 145.89px;
            text-align: center;
            padding: 1px;
            align-self: center;

            .note_input {
              width: 100%;
            }
          }
        }

      }
    }
  }

  input {
    outline: none;
    height: 15px;
    text-align: center;
  }
}

//사진대장 scss
.photoNumAddBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.photoNumAddBtn:hover {
  background-color: var(--color-primary);
  color: white;
  transition: 0.25s;
}

.photoNumBtn2 {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.active,
.photoNumBtn2:hover {
  background-color: var(--color-primary) !important;
  color: white;
  transition: 0.25s;
}

.working {
  background-color: gray;
  color: white;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.photo_page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 0 auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.subpage {
  padding: 1cm;
  height: 256mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.upload_two_img {
  width: 710px;
  height: 400px;
  padding: 30px;
  cursor: pointer;
  /* 가로세로 비율 맞추기 위해 object-fit: contain; 사용 */
}

.upload_four_img {
  width: 355px;
  height: 400px;
  padding: 20px;
  /* 가로세로 비율 맞추기 위해 object-fit: contain; 사용 */
}

.input_file_btn {
  padding: 10px 30px;
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.photolist_dropdown {
  position: relative;
  display: inline-block;
}

.photolist_dropdown:hover .photolist_toolbar_hide {
  display: flex;
}

.photolist_sub_menu {
  font-size: 15px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.photolist_sub_menu:hover {
  color: rgb(68, 132, 255);
}

.photolist_toolbar_hide {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
}

.photoNumBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.active,
.photoNumBtn:hover {
  background-color: var(--color-primary);
  color: white;
  transition: 0.25s;
}


.uploadOrImage {
  position: relative
}

.uploadOrImage2 {
  position: relative
}

.photo_content_modi_btn {
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  float: left;
  margin-left: 5px;
  width: 35px;
  height: 25px;
  border-radius: 5px;
}