.sampleStatementPage_wrapper {
  width: 1200px;
  margin: 0 auto;
  .page_title {
    width: 100%;
    padding: 40px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 32px;
    font-weight: 700;
    color: #333333;
    user-select: none;
  }
  .category_title {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  .under_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .category_description {
      font-size: 16px;
      font-weight: normal;
      color: rgb(158, 158, 158);
      line-height: 2.1em;
      text-align: left;
    }
  }
  .content_container {
    display: flex;
    flex-direction: row;
    .list_wrapper {
      width: 100%;
    }
    .page {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .filter_wrapper {
      width: 188px;
      display: flex;
      flex-direction: column;
      .filter_title {
        margin-top: 16px;
        font-size: 14px;
        color: rgb(158, 158, 158);
        user-select: none;
      }
      .filter_item {
        cursor: pointer;
        margin-top: 11px;
        font-size: 16px;
        &.selected {
          font-weight: bold;
        }
      }
      .checkbox_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        & > :first-child {
          margin-right: 10px;
          width: 24px;
          height: 24px;
          margin-right: 1px;
        }
        & > :nth-child(2) {
          font-size: 14px;
          user-select: none;
        }
      }
    }
  }
  .category_content {
    display: flex;
    border-bottom: 3px solid lightgray;
    user-select: none;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  & > .result_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    & > .category_title {
      display: flex;
      justify-content: flex-start;
      font-weight: bold;
      text-align: left;
      font-size: 18px;
      color: var(--color-primary);
      text-align: left;
    }
    & > .row_info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      & > .info_item {
        width: 100px;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
  .content_wrapper {
    & > :first-child {
      border-top: 3px solid lightgray;
      padding-top: 15px;
    }
    .sampleStatement_item {
      cursor: pointer;
      display: flex;
      border-bottom: 0.5px solid #999999;
      margin-top: 15px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title_wrapper {
        display: flex;
        flex-direction: column;
        .item_title {
          width: 400px;
          font-size: 16px;
          margin-right: 5px;
          color: rgb(64, 64, 64);
          font-weight: bold;
        }
        .item_description {
          font-size: 15px;
          color: rgb(64, 64, 64);
          margin-left: 40px;
          margin-bottom: 10px;
          white-space: pre-line;
        }
      }
      .item_info_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .item_recomend {
          width: 100px;
          justify-content: center;
          white-space: nowrap;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 17px;
          & > .icon {
            margin-right: 5px;
            margin-top: 6px;
            svg {
              font-size: 20px;
            }
            path {
              color: var(--color-primary);
            }
          }
        }
        .item_created {
          width: 100px;
          margin: 0 auto;
          text-align: center;
          white-space: nowrap;
          font-size: 17px;
        }
      }
      .keyword_container {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        margin-top: 15px;
        .item_keyword {
          font-style: normal;
          font-size: 11px;
          width: auto;
          margin-right: 1%;
          padding: 4px;
          text-align: center;
          border-radius: 25px;
          background-color: var(--color-primary);
          box-sizing: border-box;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
        }
      }
      .icon_wrapper {
        display: flex;
        align-items: center;
        margin-right: 20px;
        svg {
          cursor: pointer;
          font-size: 35px;
        }
        path {
          color: rgb(68, 132, 255);
        }
      }
    }
  }
}
