// html,
// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// // b,
// // u,
// // i,
// // center,
// // dl,
// // dt,
// // dd,
// // ol,
// // ul,
// // li,
// fieldset,
// // form,
// // label,
// legend,
// // table,
// // caption,
// // tbody,
// // tfoot,
// // thead,
// // tr,
// // th,
// // td,
// // article,
// // aside,
// // canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }

// ol,
// ul {
//   list-style: none;
// }
// blockquote,
// q {
//   quotes: none;
// }
// blockquote:before,
// blockquote:after,
// q:before,
// q:after {
//   content: "";
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

.page-doc {
  width: 21cm;
  min-height: 29.7cm;
  max-height: 29.7cm;
  padding: 2cm;
  margin: 0 auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .subpage {
    padding: 1cm;
    height: 256mm;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }

  table {
    width: 100%;
    height: 100%;
    border: 1px solid #333333;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #333333;
  }

  .title {
    font-size: 50px;
    font-weight: bold;
  }

  .nonLine {
    border: 0px solid white;
  }
}

:root {
  --color-primary: #3343e8;
  --color-sub: #8791fa;
  --color-sub-error: #e84b4a;
  --color-sub-yellow: #e8bc1c;
  --color-sub-green: #27e85f;
  --color-gray1: #fefefe;
  --color-gray2: #fdfdfd;
  --color-gray3: #f7f8f9;
  --color-gray4: #e9ebee;
  --color-gray5: #c5c8ce;
  --color-gray6: #646f7c;
  --color-gray7: #374553;
  --color-gray8: #28323c;
  --color-gray9: #161d24;
  --color-border: var(--color-gray4);
  --shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  --color-big: #91b8ff;
  --color-middle: rgba(255, 241, 133, 0.7);
  --color-video-primary: #9013fe;
  --color-video-sub: #d665ff;
  --font-tenada: "Tenada", sans-serif;
  // --color-success: #{mat-color($app-success)};
}

@font-face {
  font-family: "Tenada";
  src: url("/assets/fonts/Tenada.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "Spoqa Han Sans";
//   font-weight: 700;
//   src: url("./assets/fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
//     url("./assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
//     url("./assets/fonts/SpoqaHanSansNeo-Bold.eot") format("embedded-opentype"),
//     url("./assets/fonts/SpoqaHanSansNeo-Bold.otf") format("opentype");
// }

// @font-face {
//   font-family: "Spoqa Han Sans";
//   font-weight: 400;
//   src: url("./assets/fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
//     url("./assets/fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
//     url("./assets/fonts/SpoqaHanSansNeo-Regular.eot")
//       format("embedded-opentype"),
//     url("./assets/fonts/SpoqaHanSansNeo-Regular.otf") format("opentype");
// }

// @font-face {
//   font-family: "Spoqa Han Sans";
//   font-weight: 300;
//   src: url("./assets/fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
//     url("./assets/fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
//     url("./assets/fonts/SpoqaHanSansNeo-Light.eot") format("embedded-opentype"),
//     url("./assets/fonts/SpoqaHanSansNeo-Light.otf") format("opentype");
// }

// @font-face {
//   font-family: "Spoqa Han Sans";
//   font-weight: 100;
//   src: url("./assets/fonts/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
//     url("./assets/fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
//     url("./assets/fonts/SpoqaHanSansNeo-Thin.eot") format("embedded-opentype"),
//     url("./assets/fonts/SpoqaHanSansNeo-Thin.otf") format("opentype");
// }

// * {
//   font-family: "Spoqa Han Sans", "본고딕", sans-serif !important;
//   box-sizing: border-box !important;
// }

// .width-center {
//   margin: 0 auto;
// }

// .material-icons {
//   font-family: "Material Icons" !important;
// }

// .document-page-warpper {
//   width: 1585px;
//   min-height: calc(100% - 45px);
//   // overflow-y: scroll;
//   background-color: white;
// }

// .page-wrapper {
//   width: 100%;
//   min-height: calc(100% - 45px);
//   background-color: white;
// }

// .ellipsis {
//   text-overflow: ellipsis !important;
//   white-space: nowrap !important;
//   word-wrap: normal !important;
//   overflow: hidden !important;
// }

// .add-price-calulation-background {
//   background-color: rgba(0, 0, 0, 0.32);
// }

// .date-picker-class {
//   width: 300px;
//   max-height: 300px;
//   overflow-y: auto;
// }

// angular-editor-toolbar button i {
//   font-family: FontAwesome !important;
//   color: #474d6d !important;
// }

// // html,
// // body {
// //   height: 100%;
// // }

// // body {
// //   margin: 0;
// //   font-family: Roboto, "Helvetica Neue", sans-serif;
// // }

// .notice-content {
//   width: 100%;

//   * {
//     max-width: 100%;
//   }
// }

// .color-red {
//   color: var(--color-error) !important;
//   * {
//     color: var(--color-error) !important;
//   }
// }

// .color-black {
//   color: var(--color-font) !important;
//   * {
//     color: var(--color-font) !important;
//   }
// }

// .back-white {
//   background-color: white !important;
//   * {
//     background-color: white !important;
//   }
// }

// button {
//   background-color: var(--color-primary);
//   padding: 0 14px;
//   font-size: 14px;
//   height: 40px;
//   border-radius: 4px;
//   transition: 0.15s;
//   color: #fff;
//   font-weight: bold;
//   border: none;
//   outline: 0;
//   min-width: 100px;
//   cursor: pointer;
//   &:hover {
//     opacity: 0.85;
//   }
// }

// button.small {
//   font-size: 10px;
//   height: 26px;
//   padding: 0 8px;
//   min-width: 0 !important;
// }

// button.large {
//   font-size: 16px;
//   height: 50px;
//   padding: 0 18px;
// }

// button.default {
//   background-color: var(--color-gray4);
//   color: var(--color-gray7);
//   &:hover {
//     background-color: var(--color-gray5);
//   }
// }

// button.modal-close {
//   border: 1px solid var(--color-gray6);
//   color: var(--color-gray6);
//   background-color: rgba(0, 0, 0, 0);
//   &:hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
// }

// button.simple {
//   min-width: 80px;
//   height: 26px;
//   padding: 0;
//   margin: 0;
//   background-color: white;
//   border: solid 1px #c5c5c5;
//   color: #646f7c;
//   font-weight: normal;
//   border-radius: 13px;
//   font-size: 13px;
// }

// button.video {
//   background-color: var(--color-video-primary);
// }

// .button-group {
//   button {
//     margin-right: 8px;
//     &:last-child {
//       margin-right: 0;
//     }
//   }
// }

// input[type="text"] {
//   height: 40px;
//   border: 1px solid var(--color-border);
//   background-color: var(--color-gray2);
//   padding: 0 10px;
//   color: var(--color-gray7);
//   border-radius: 4px;
//   outline: 0;
//   transition: 0.15s;
//   &:focus {
//     border: 1px solid var(--color-sub);
//   }
//   &:disabled {
//     opacity: 0.9;
//     background-color: var(--color-gray3);
//     color: var(--color-gray6);
//     cursor: not-allowed;
//   }
// }
// textarea {
//   height: 160px;
//   border-radius: 4px;
//   background-color: var(--color-gray2);
//   padding: 14px 10px;
//   border: 1px solid var(--color-border);
//   resize: none;
//   outline: 0;
//   color: var(--color-gray7);
//   transition: 0.15s;
//   &:focus {
//     border: 1px solid var(--color-sub);
//   }
// }

// .tags {
//   float: left;
//   div {
//     background-color: var(--color-primary);
//     padding: 4px 8px;
//     border-radius: 4px;
//     font-size: 11px;
//     color: #fff;
//     text-align: center;
//     float: left;
//     margin-right: 4px;
//   }
// }
// .notification {
//   background: #fe6f61;
//   color: #fff;
//   font-size: 11px;
//   border-radius: 50%;
//   width: 24px;
//   height: 24px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

// .icon-input-wrap {
//   width: fit-content;
//   height: fit-content;
//   position: relative;
//   button {
//     min-width: 0;
//     padding: 0 !important;
//     width: 40px;
//     height: 40px;
//     border-style: none;
//     font-size: 20px;
//     font-weight: bold;
//     outline: none;
//     cursor: pointer;
//     position: absolute;
//     border-radius: 4px;
//     left: 0;
//     background-color: var(--color-gray3);
//     pointer-events: pointer;

//     &:focus ~ input {
//       width: 220px;
//       border-radius: 0px;
//       background-color: var(--color-gray3);
//       transition: 0.25s;
//       padding-left: 40px;
//     }
//   }
//   input {
//     height: 40px;
//     width: 40px;
//     border-style: none;
//     font-size: 14px;
//     outline: none;
//     transition: 0.25s ease-in-out;
//     background-color: #fff;
//     padding-right: 40px;
//     color: var(--color-gray7);
//     // border-bottom: 2px solid transparent;
//     &::placeholder {
//       color: var(--color-border);
//       font-size: 14px;
//       letter-spacing: 2px;
//       font-weight: 100;
//     }
//     &:focus {
//       width: 250px;
//       border-radius: 0px;
//       background-color: var(--color-gray3);
//       // border-bottom: 2px solid var(--color-primary);
//       transition: 0.2s;
//       padding-left: 40px;
//     }
//   }
// }
// .white-icon {
//   svg {
//     path {
//       color: var(--color-primary);
//     }
//   }
// }

// .modal-wrap {
//   width: 100%;
//   height: 100%;
//   position: absolute;
// }

// .main-wrap {
//   width: 1200px;
//   display: flex;
//   flex-direction: column;
//   margin: 0 auto;
// }
