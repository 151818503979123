.row-fade-in {
  animation: fadeIn 0.5s ease-in;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.statement-table {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  border-collapse: collapse;
  // 테이블 헤더
  .statement-table-header {
    .header-row {
      display: flex;
      width: 100%;
      align-items: stretch;

      &.basic-main-header {
        background: #e9e9e9;
        border: 1px solid #dee2e6;
        .statement-table-header-item {
          color: #495057;
          font-size: 0.9em;
          font-weight: 500;
          padding: 16px 0;
          font-weight: 600;
          letter-spacing: 0.5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.main-header {
        background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
        color: white;

        .statement-table-header-item {
          padding: 16px 0;
          font-weight: 600;
          letter-spacing: 0.5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.column-group {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }

      &.sub-header {
        background: #f8f9fa;
        border-bottom: 2px solid #dee2e6;

        .statement-table-header-item {
          padding: 12px 16px;
          color: #495057;
          font-size: 0.9em;
          font-weight: 500;

          &.empty-cell {
            border-bottom: none;
          }

          &.price-columns {
            display: flex;
            justify-content: space-between;
            padding: 8px 16px;

            span {
              flex: 1;
              text-align: center;
              padding: 4px 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-child {
                border-right: 1px solid #dee2e6;
              }
            }
          }
        }
      }
    }

    .statement-table-header-item {
      &:nth-child(1) {
        width: 22%; // 품명
      }
      &:nth-child(2) {
        width: 15%; // 규격
      }
      &:nth-child(3) {
        width: 10%; // 단위
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: calc((100% - 22% - 15% - 10%) / 4);
      }

      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  // 테이블 바디
  .statement-table-row {
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.row-fade-in {
      visibility: visible;
      animation: fadeIn 0.5s ease forwards;
    }

    display: flex;
    align-items: stretch;
    transition: all 0.3s ease;
    border-bottom: 1px solid #e9ecef;
    opacity: 0;
    transform: translateY(10px);
    animation: rowFadeIn 0.3s ease forwards;

    .statement-table-cell {
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-child(1) {
        width: 22%; // 품명
        justify-content: flex-start;
        text-align: left;
        padding-left: 16px;
      }
      &:nth-child(2) {
        width: 15%; // 규격
        justify-content: flex-start;
        text-align: left;
        padding-left: 16px;
      }
      &:nth-child(3) {
        width: 10%; // 단위
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: calc((100% - 22% - 15% - 10%) / 4);
      }

      &:not(:last-child) {
        border-right: 1px solid #e9ecef;
      }

      .price-group {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .unit-price,
        .total-price {
          flex: 1;
          text-align: center;
          padding: 0 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .unit-price {
          border-right: 1px solid #e9ecef;
        }
      }
    }
  }

  // 숫자 값을 위한 스타일
  .number-cell {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    color: #2c3e50;
  }

  // 비고 컬럼
  .remark-cell {
    color: #6c757d;
    font-size: 0.9em;
    background: #f8f9fa;
  }
}
.basic-title-row {
  background: #f8f9fa;
  .statement-table-cell {
    color: var(--color-primary) !important;
    border-color: #e9e9e9 !important;
  }
}

.basic-subtitle-row {
  background: #f8f9fa;
  .statement-table-cell {
    border-color: #e1e1e1 !important;
  }
}
.title-row {
  background-color: rgba(52, 152, 219, 0.2);
}
.subtitle-row {
  background-color: rgba(52, 152, 219, 0.1);
}
// 반응형 디자인
@media (max-width: 1200px) {
  .statement-table {
    .statement-table-header-item,
    .statement-table-cell {
      padding: 12px 8px;
    }

    .header-row.sub-header .statement-table-header-item.price-columns {
      font-size: 0.8em;
    }
  }
}

@media (max-width: 768px) {
  .statement-table {
    font-size: 14px;
  }
}

.bold {
  font-weight: bold;
}

@keyframes rowFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.statement-table-footer-item {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .statement-table-footer-item-button {
    width: 100%;
    background: var(--color-primary);
    color: white;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }
}
