.lecture_curriculum_wrapper {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  flex-direction: column;
  //  border-bottom: 1px solid #e5e5e5;
  .curriculum_container {
    width: 1200px;
    padding-top: 70px;
    margin-bottom: 2rem;
    padding-bottom: 70px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-bottom: 2rem;
      font-size: 2.5em;
    }
    p {
      margin-bottom: 2rem;
      font-size: 1.8em;
    }
    button {
      width: auto;
      height: auto;
      margin: 4px 0;
      margin-left: 15px;
      padding: 4px 8px;
      border-radius: 5px;
      background-color: #fff;
      color: #9013fe;
      font-size: 13px;
      font-weight: 600;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &:hover {
        background-color: #9013fe;
        color: #fff;
      }
    }
    .summary_on_off_button{
      width: 50%;
      display: flex;
      justify-content: right;
      align-items:flex-end;
    }
    .curriculum_body {
      width: 100%;
      overflow: hidden;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      .curriculum_list {
        width: 50%;
        margin-bottom: 60px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .video_list_folding {
          width: 100%;
          summary {
            width: 100%;
            margin-top: 10px;
            padding: 16px;
            padding-left: 50px;
            list-style: none;
            font-size: 20px;
            font-weight: bold;
            text-align: justify;
            background-color: #f2f2f3;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &:hover {
              background-color: rgba(68, 132, 255, 0.2);
            }
            &.active {
              background-color: #9013fe;
              color: #fff;
            }
            .purple {
              size: 30;
              fill: #9013f2;
            }
            .white {
              size: 30;
              fill: whitesmoke;
            }
          }
          .video_list_item {
            display: flex;
            flex-direction: column;
            li {
              padding: 10px 0px 10px 80px;
              display: flex;
              align-items: center;
              width: 100%;
              font-size: 16px;
              font-weight: bold;
              list-style: none;
              background-color: #f2f2f3;
              cursor: pointer;
              .ai_video {
                margin-right: 6px;
                fill: #9013fe;
              }
              &:last-child {
                border-radius: 0 0 5px 5px;
              }
              &.active {
                background-color: rgba(144, 19, 254, 0.3);
              }
              &:hover {
                background-color: rgba(68, 132, 255, 0.2);
              }
              .free_button {
                width: auto;
                height: auto;
                margin: 4px 0;
                margin-left: 15px;
                padding: 4px 8px;
                border-radius: 5px;
                background-color: #fff;
                color: #9013fe;
                font-size: 13px;
                font-weight: 600;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
                  0 1px 5px 0 rgba(0, 0, 0, 0.19);
                cursor: pointer;
                &:hover {
                  background-color: #9013fe;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
