.lecture_wrapper {
  width: 1200px;
  margin: 0 auto;
  .project_title {
    width: 100%;
    padding: 40px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 32px;
    font-weight: 700;
    color: #333333;
    user-select: none;
  }
  .lecture_contents_wrapper {
    display: flex;
    margin-top: 60px;
    user-select: none;
    .category_title {
      font-weight: bold;
      font-size: 24px;
      color: #494949;
      text-align: left;
      margin-bottom: 20px;
    }
    .category_description {
      font-size: 16px;
      font-weight: normal;
      color: rgb(158, 158, 158);
      line-height: 2.1em;
      text-align: left;
    }
  }
  .lecture_contents {
    user-select: none;
    width: 100%;
    float: left;
    .lecture_content_wrapper {
      display: flex;
      width: calc(100% / 4);
      flex-direction: column;
      padding: 0 5px;
      float: left;
      height: 266px;
    }
    .img_wrapper {
      cursor: pointer;
      width: 100%;
      height: 163px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
    .text_wrapper {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      margin-top: 5px;
      .lecture_title {
        font-weight: bold;
        color: #494949;
        font-size: 18px;
        line-height: 2.1em;
        text-align: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .lecture_description {
        font-size: 14px;
        font-weight: normal;
        color: #666;
        line-height: 1.6em;
        text-align: left;
        white-space: pre-line;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        text-overflow: ellipsis;
      }
    }
    .play_vedio_wrapper {
      user-select: none;
      justify-content: center;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      .play_vedio {
        cursor: pointer;
        text-align: center;
        width: 179px;
        height: 38px;
        font-size: 15px;
        color: rgb(68, 132, 255);
        line-height: 2.3em;
        border-radius: 4px;
        background-color: white;
        font-weight: bold;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(68, 132, 255);
        svg {
          fill: rgb(68, 132, 255);
          width: 35px;
          height: 35px;
          position: absolute;
        }
      }
    }
  }
  .study_mode_wrapper {
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    .vedio_with_lecture_list {
      display: flex;
      flex-direction: column;
      .iframe_wrapper {
        width: 745px;
        height: 420px;
        border-radius: 6px;
        overflow: hidden;
        html {
          width: 100%;
          height: 100%;
        }
      }
    }
    .lecture_text_contents_wrapper {
      width: 746px;
      margin-top: 30px;
      .lecture_date {
        color: #868e96;
        font-size: 16px;
        margin-top: 10px;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #f1f3f5;
      }
      & > .title {
        font-size: 24px;
        color: #495057;
        font-weight: bold;
      }
      & > .description {
        color: #495057;
        font-size: 15px;
        font-weight: normal;
        margin-top: 20px;
        white-space: pre-line;
      }
    }
  }
}
.play_list_wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  width: 400px;
  .list_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > .item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      .thumbnail {
        width: 165px !important;
        height: 94px !important;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item_text_wrapper {
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & > .title {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 14px;
          word-break: keep-all;
        }
        & > .description {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 라인수 */
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          line-height: 1.6em;
          font-size: 13px;
          color: #868e96;
        }
      }
    }
  }
}
