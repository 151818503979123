.mypage-payment-content-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-border);
  .mypage-content-none-tr {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 13px;
      color: var(--color-gray6);
    }
  }
  .mypage-content-tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .th {
      padding: 16px 0;
      font-size: 14px;
      font-weight: bold;
      border-top: 2px solid var(--color-border);
      background-color: var(--color-gray3);
      text-align: center;
      &:first-child {
        width: 100px;
      }
      &:nth-child(2) {
        width: 250px;
      }
      &:nth-child(3) {
        flex: 1;
      }
      &:nth-child(4) {
        width: 200px;
      }
      &:nth-child(5) {
        width: 150px;
      }
      &:nth-child(6) {
        width: 100px;
      }
      &:nth-child(7) {
        width: 100px;
      }
    }
    .td {
      padding: 16px 0;
      font-size: 13px;
      border-top: 1px solid var(--color-border);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 52px;
      &:first-child {
        width: 100px;
      }
      &:nth-child(2) {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &:nth-child(3) {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &:nth-child(4) {
        width: 200px;
        display: flex;
        align-items: center;
      }
      &:nth-child(5) {
        width: 150px;
      }
      &:nth-child(6) {
        width: 100px;
      }
      &:nth-child(7) {
        width: 100px;
      }
      span {
        margin: 3px;
        color: var(--color-primary);
        font-weight: bold;
        cursor: pointer;
      }
      img {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }
  }
}
