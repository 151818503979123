.doc-container-box {
  width: 816px;
  border: 1px solid #e9e9e9;
  border-top: 5px solid var(--color-primary);
  display: flex;
  justify-content: center;
  position: relative;
  .doc-header {
    margin: 50px;
    text-align: center;
  }

  .doc-label {
    font-size: 14px;
    padding-right: 30px;
  }

  .doc-submit-input {
    user-select: none;
    width: 350px;
    height: 30px;
    float: left;
    padding-left: 10px;
    margin-right: 20px;
    border: 1px solid #d9d9d9;
    font-weight: 600;
    &::placeholder {
      font-weight: 100;
      color: rgba(0, 0, 0, 0.4);
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-weight: 100;
      }
      &:hover {
        cursor: not-allowed;
        border: 1px solid #d9d9d9;
      }
    }
    &:hover {
      border: 1px solid #1890ff;
      transition: border 0.3s;
    }
  }

  .info_line {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    text-align: right;
    .ref_btn {
      color: white;
      background-color: #3498db;
      width: 50px;
      height: 30px;
      cursor: pointer;
    }
  }
  .info_sign {
    margin-top: -10px;
    font-size: 13px;
    margin-bottom: 10px;
    text-align: right;
    margin-right: 55px;
  }
  #infoForm > div > div > div > div > div > div > div > input {
    font-size: 14px;
    height: 30px;
  }
  #infoForm > div > div > .ant-form-item {
    margin-bottom: 10px;
  }
  .button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-right: 22px;
  }
  .doc_button_modi {
    width: 100px;
    background-color: #bebebe;
    height: 30px;
    padding: 0px;
    cursor: pointer;
  }
  .doc_submit {
    width: 100px;
    background-color: #3498db;
    height: 30px;
    padding: 0px;
    cursor: pointer;
  }
  .btn_hidden {
    display: none;
  }
}
.repair_ref_chart {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-size: 20px;
      }
    }
  }
}
