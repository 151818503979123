.postDetailContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.postDetailCard {
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.postHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.postContent {
  margin: 20px 0;
  word-break: break-word;
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
  }
}

.postFooter {
  display: flex;
  justify-content: flex-end;
}

.commentList {
  margin-top: 30px;
}

.commentItem {
  border-bottom: 1px solid #f0f0f0;
  padding: 16px 0;
}

.commentForm {
  margin-top: 30px;
}

.likeIcon {
  color: #ff4d4f;
  margin-right: 8px;
}

.commentIcon {
  color: #1890ff;
}

.iconButton {
  display: flex;
  align-items: center;

  :global(.anticon) {
    margin-right: 4px;
  }

  &.liked {
    color: #1890ff;
  }
}

:global {
  .ant-modal-mask {
    z-index: 1059 !important; // 모달 배경의 z-index를 설정합니다.
  }
  .ant-modal-wrap {
    z-index: 1060 !important; // 모달 컨테이너의 z-index를 설정합니다.
  }
}

.backButton {
  margin-bottom: 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s;

  &:hover, &:focus {
    background-color: #40a9ff;
    color: white;
  }

  .anticon {
    margin-right: 8px;
  }
}

.attachmentLink {
  cursor: pointer;
  color: #1890ff;
  
  &:hover {
    text-decoration: underline;
  }
}

.commentContent {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;  // 또는 원하는 높이
}
