.photoNumAddBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.photoNumAddBtn:hover {
  background-color: var(--color-primary);
  color: white;
  transition: 0.25s;
}

.photoNumBtn2 {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin-right: 10px;
}

.active,
.photoNumBtn2:hover {
  background-color: var(--color-primary) !important;
  color: white;
  transition: 0.25s;
}

.working {
  background-color: gray;
  color: white;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.photo_page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 0 auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.subpage {
  padding: 1cm;
  height: 256mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.upload_two_img {
  width: 710px;
  height: 400px;
  padding: 30px;
  cursor: pointer;
  /* 가로세로 비율 맞추기 위해 object-fit: contain; 사용 */
}

.upload_four_img {
  width: 355px;
  height: 400px;
  padding: 20px;
  /* 가로세로 비율 맞추기 위해 object-fit: contain; 사용 */
}

.input_file_btn {
  padding: 10px 30px;
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.photolist_dropdown {
  position: relative;
  display: inline-block;
}

.photolist_dropdown:hover .photolist_toolbar_hide {
  display: flex;
}

.photolist_sub_menu {
  font-size: 15px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.photolist_sub_menu:hover {
  color: rgb(68, 132, 255);
}

.photolist_toolbar_hide {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
}

.photoNumBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.active,
.photoNumBtn:hover {
  background-color: var(--color-primary);
  color: white;
  transition: 0.25s;
}


.uploadOrImage {
  position: relative
}

.uploadOrImage2 {
  position: relative
}

.photo_content_modi_btn {
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  float: left;
  margin-left: 5px;
  width: 35px;
  height: 25px;
  border-radius: 5px;
}