.request_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    & > .center_wrap {
      margin: 0 auto;
      & > .request_title {
        padding-top: 89px;
        padding-bottom: 21px;
        font-size: 32px;
        font-weight: 700;
        color: #333333;
        user-select: none;
        text-align: center;
      }
      & > .subtitle {
        font-size: 15px;
        text-align: center;
        color: var(--color-gray5);
        margin-bottom: 79px;
      }
      & > .form_wrap {
        width: 1200px;
        padding: 21px 113px 68px;
        border-top: solid 2px var(--color-primary);
        background-color: var(--color-gray3);
        input {
          padding-left: 10px;
        }
        & > .form_group {
          color: var(--color-gray6);
          display: flex;
          flex-direction: column;
          align-items: stretch;
          margin-top: 50px;
          & > .group_title {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 21px;
            & > .text {
              width: 100px;
              white-space: nowrap;
              margin-right: 64px;
              font-size: 21px;
              font-weight: bold;
            }
            & > .line {
              width: 811px;
              height: 1px;
              background-color: var(--color-gray5);
            }
          }
          & > .form_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 9px;
            & > :first-child {
              width: 15px;
            }
            .require {
              color: #f00;
              display: flex;
            }
            & > .form_name {
              width: 150px;
              color: var(--color-gray6);
              font-size: 17px;
            }
            & > .info_input {
              width: 348px;
              height: 41px;
              border-radius: 4px;
              border: solid 1px var(--color-gray4);
              background-color: var(--color-gray2);
            }
            & > .full_width_input {
              flex: 1;
              height: 41px;
              border-radius: 4px;
              border: solid 1px var(--color-gray4);
              background-color: var(--color-gray2);
            }
            & > .email_char {
              margin: 0 15px;
            }
            & > .email_input {
              width: 242px;
              height: 41px;
              border-radius: 4px;
              border: solid 1px var(--color-gray4);
              background-color: var(--color-gray2);
            }
            & > .radio_wrapper {
            //   width: 1000px;
              height: 40px;
              flex-grow: 1;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              & > .check_box {
                margin-right: 19px;
                label {
                  white-space: nowrap;
                  font-size: 15px;
                }
                input {
                  margin: 3px;
                }
              }
              &>input {
                width: 150px;
              }
            }
            & > .content {
              padding: 10px;
              flex: 1;
              height: 294px;
              border-radius: 4px;
              border: solid 1px #e9ebee;
              background-color: #fdfdfd;
              margin-bottom: 12px;
            }
            & > .select_file {
              height: 41px;
              padding: 5px 15px 0 15px;
              border-radius: 4px;
              border: solid 1px #c5c8ce;
              background-color: #e9ebee;
              cursor: pointer;
              white-space: nowrap;
              margin-right: 10px;
            }
            & > .input_attach_files {
              flex:1;
              height: 40px;
              max-height: auto;
              border: 1px solid rgb(224, 224, 224);
              padding-top: 3px;
              padding-left: 10px;
              text-shadow: 0 0 0 black;
              justify-content: center;
              word-break: break-all;
              overflow: hidden;
              resize: none;
            }
          }
          & > .divider {
            margin: 48px 0;
            width: 100%;
            border-top: solid 1px var(--color-gray4);
          }
          & > .summit {
            width: 190px;
            height: 40px;
            margin: 12px auto;
          }
          & > .confirm_message_wrapper {
            margin: 0 auto;
            .necessary {
              font-size: 14px;
              color: rgb(192, 57, 43);
            }
            .policy {
              cursor: pointer;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
  