#doc-header {
    margin-top: 50px;
    padding: 100px;
    text-align: center;
}

.doc-card {
    width: 200px;
    margin: 8px;
    height: 160px;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.doc-title {
    font-size: 20px;
    font-weight: 600;
}

.ant-row {
    display: flex;
    justify-content: center;
}

.docListBtn {
    width: 90px;
    height: 40px;
    background-color: #3498db;
    color: white;
    float: right;
    margin-top: 80px;
}