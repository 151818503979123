/* body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    /* font-size: 15px; 
    letter-spacing: 1px !important;
}

.subpage {
    padding: 1cm;
    height: 256mm;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        padding: initial;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

table {
    width: 100%;
    height: 100%;
    border: 1px solid #333333;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #333333;
}

.doc-title {
    font-size: 50px;
    font-weight: bold;
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
}


.nonLine {
    border: 0px solid white;
}
*/
.circleBtn {
    width: 95px;
    height: 95px;
    border-radius: 100%;
    box-shadow: 1px 1px 3px 0px rgb(224,224,224);
    border: 1px solid rgb(224,224,224);
    cursor: pointer;
    margin-right: 10px;
  }
