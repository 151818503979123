.makeFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex_clmn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex_row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.jc_STR {
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
}

.jc_FS {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.jc_C {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.jc_FE {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.jc_SB {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ai_STR {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ai_FS {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ai_C {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ai_FE {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.upload-form table {
  width: 100%;
  table-layout: fixed;
}

.upload-form table tr {
  border-radius: 0.5rem;
  box-shadow: 0 5px 12px #0000000d;
}

.upload-form table th,
.upload-form table td {
  width: 100%;
  background: #fff;
}
.upload-form table td {
  padding: 1rem;
}

@media (max-width: 768px) {
  .upload-form table tr {
    border-radius: 0.5rem;
    box-shadow: none;
  }

  .upload-form table td {
    padding: 1rem 0;
  }

  .filebox {
    position: relative;
    flex-basis: 80%;
  }

  .upload-name {
    display: block;
    position: relative;
    width: 70%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 0;
    cursor: text;
  }
  .filebox label {
    display: inline-block;
    position: relative;
    width: 29%;
    padding: 0.5em 0;
    margin-left: 0;
    border: 1px solid var(--bs-gray-300);
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    color: var(--bs-gray-500);
    cursor: pointer;
    border-radius: 0.5rem;
  }
}

/* 첨부파일 */
.ipt_file_area {
  position: relative;
  width: 100%;
}
.ipt_file_area > * {
  margin: 0.5em;
}

.filebox {
  position: relative;
  flex: 4;
}

.changeFileName {
  position: absolute;
  z-index: 5;
  top: -18px;
  left: 0;
  padding-left: 1.5em;
  font-size: 12px;
  color: var(--bs-gray-400);
  cursor: pointer;
}

.changeFileName.on {
  font-weight: bold;
  color: #222;
}

.changeFileName::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 1em;
  height: 1em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath d='M120-120v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm584-528 56-56-56-56-56 56 56 56Z'/%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
}

.upload-name {
  display: block;
  position: relative;
  width: 76%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  border: 0;
  cursor: text;
}

.upload-name:focus {
  background-color: #f8fafc;
  outline: 1px solid var(--bs-gray-300);
}

.filebox label {
  display: inline-block;
  position: relative;
  width: 20%;
  padding: 0.5em 0;
  margin-left: 1em;
  border: 1px solid var(--bs-gray-300);
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
  color: var(--bs-gray-500);
  cursor: pointer;
  border-radius: 0.5rem;
}

.filebox input[type="file"] {
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: -1px;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.btn_del {
  display: block;
  position: relative;
  margin-top: 0.5rem;
  padding: 7px;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--bs-danger-bg-subtle);
  color: var(--bs-danger);
}
