#container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.fs12{
  font-size: 12px;
}
#left-side {
  width: 250px;
  /* border: 1px solid rgb(190, 190, 190); */
  margin-right: 10px;
  margin-top: 10px;
}

#right-side {
  width: 940px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.constructionName-box {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: rgb(68, 132, 255);
  border: 1px solid rgb(68, 132, 255);
  border-bottom-right-radius: 30px;
  word-break: break-all;
  word-wrap: break-word;
  padding: 20px;
  word-wrap: break-word;
}

.ant-tree-iconEle {
  width: 0px !important;
  font-size: 0px !important;
  height: 35px !important;
}

.ant-tree-switcher-icon svg {
  font-size: 20px;
  margin-top: 13px;
  color: rgb(94, 94, 94);
}

.ant-tree-treenode .ant-tree-node-content-wrapper {
  margin-top: 11px;
  min-height: 0px !important;
}

.ant-tree-treenode {
  /* box-shadow: rgb(240 240 240) -5px 0px 6px 1px inset; */
  padding: 0 !important;
  border-left: 1px solid rgb(190, 190, 190);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.ant-tree-treenode:last-child {
  border-bottom: none;
}

.ant-tree-node-content-wrapper-normal {
  min-height: 23px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-color: white;
}

.document-tree>.ant-tree-list>.ant-tree-list-holder>div>.ant-tree-list-holder-inner>div>.ant-tree-switcher.ant-tree-switcher_open,
.ant-tree-switcher.ant-tree-switcher_close {
  margin-left: 10px;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: none !important;
}

.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open>span>span {
  color: rgb(68, 132, 255);
}

.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.ant-tree-node-selected>span {
  color: rgb(68, 132, 255);
  font-weight: 600;
}

.tree-child {
  background-color: #e9e9e9;
  font-size: 12px;
  font-weight: bold;
}

.doc-one-card {
  width: 280px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 325px;
  padding-bottom: 15px;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.doc-card-wrapper {
  display: flex;
}

.doc-card-title {
  font-size: 17px;
  font-weight: 600;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.doc-card-child {
  font-size: 16px;
  padding-left: 25px;
  display: inline-grid;
  line-height: 25px;
}

.print-tree {
  display: inline-block !important;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: var(--color-primary);
  box-shadow: none;
}

.print-tree-btn {
  font-weight: 600;
  font-size: 17px;
  color: white;
  height: 60px;
  line-height: 60px;
  width: 100%;
  background-color: var(--color-primary);
  cursor: pointer;
}

.tree_blink {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  0% {
    background-color: var(--color-primary);
  }

  100% {
    background-color: #e9e9e9;
  }
}