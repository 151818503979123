#new-container {
  display: flex;
  justify-content: center;
}

#new-work-header {
  padding: 80px !important;
  text-align: center;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 100px;
  font-size: 16px;
  line-height: 100px;
  font-weight: 600;
  margin: 8px;
  width: 165px;
  text-align: center;
  position: relative;
}
.ant-radio-button-wrapper .ready {
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translate(-50%, 0);
  
}

.new-label {
  font-size: 18px;
  width: 80px;
}

.ant-select-selection-placeholder {
  font-weight: 600;
}
.ant-input {
  outline: 0;
}
.ant-input:placeholder-shown {
  font-weight: 600;
}

#new-container > form > div > .ant-col {
  margin-right: 30px;
}

.ant-row.ant-form-item.contract_kind_label > .ant-col.ant-form-item-label {
  align-self: center;
}
