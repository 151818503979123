.pcc_container {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .warning_image {
    background-image: url("https://sulfac-image.s3.ap-northeast-2.amazonaws.com/None_1670902823678432.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    width: 100%;
    height: 100%;
  }
  .close {
    position: absolute;
    bottom: 50px;
  }
}
