.search-result {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #fff;
  .search-result-aside {
    position: fixed;
    width: 240px;
    background-color: #fff;
    box-shadow: var(--shadow);
    margin-top: 50px;
    left: -240px;
    transition: left 0.3s;
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 48px;
    border-radius: 8px;
    .search-result-aside-content-history-list {
      max-height: 350px;
      overflow-y: scroll;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
    .aside-flag {
      left: 100%;
      top: 0;
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      gap: 8px;
      padding: 12px;
      cursor: pointer;
      transition: left 0.3s;
      border-radius: 8px;
    }
    .search-result-aside-content-history {
      display: flex;
      flex-direction: column;
      padding: 8px;
      border-radius: 8px;
      transition: background-color 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }
      &.active {
        background-color: #f5f5f5;
      }
      .search-result-aside-content-name {
        width: 100%;
        font-size: 14px;
        padding: 4px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .search-result-aside-content-date {
        font-size: 12px;
        color: #666;
      }
    }
    .search-result-aside-content-item {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      .search-result-aside-content-title {
        width: 100%;
        font-size: 16px;
        padding: 8px 0;
      }
    }
    .search-result-aside-content-item-primary {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-size: 16px;
      border: 1px solid var(--border-color);
      padding: 16px;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;
      &:hover {
        background-color: var(--color-primary);
        color: #fff;
      }
    }
  }
  .search-result-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
    .search-result-content-body {
      width: 100%;
      flex: 1;
      display: flex;
      padding: 16px;
      justify-content: center;
      align-items: flex-start;
      background-color: #f1f1f1;
      .search-result-content-body-item-list {
        max-width: 900px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 24px 0 24px;
        padding-bottom: 48px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
        align-self: flex-start;
        .search-result-content-body-title {
          font-size: 24px;
          font-weight: 600;
          width: 100%;
          padding: 8px 0;
        }
        .search-result-content-body-item {
          width: 100%;
          display: flex;
          justify-content: start;
          flex-direction: column;
          gap: 8px;
          .search-result-content-body-item-title {
            font-size: 20px;
            font-weight: 600;
            padding: 8px 0;
            span {
              margin-left: 8px;
            }
          }
          .search-item {
            width: 100%;
            h2 {
              font-size: 22px;
              font-weight: 600;
            }
            h3 {
              font-size: 20px;
            }
          }
          .search-item {
            .ant-design-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              font-size: 24px;
            }
          }
        }
      }
      .search-result-content-body-citations {
        width: 360px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
        align-self: flex-start;
        .search-result-content-body-citations-item-title {
          font-size: 24px;
          font-weight: 600;
          padding: 8px 0;
        }
        .search-result-content-body-citations-item-list {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .citation-section {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            background-color: #fff;
            border: 1px solid #e9e9e9;
          }
          .search-result-content-body-citations-item-list-item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px;
            border-radius: 8px;
            box-shadow: var(--shadow);
            background-color: #fff;
            color: #222;
            .preview-img-container {
              width: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .preview-title-row {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 16px;
              font-weight: 600;
              width: 100%;
              .preview-index {
                font-size: 14px;
                font-weight: 600;
                background-color: var(--color-primary);
                color: #fff;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
              }
              .preview-title {
                font-size: 16px;
                font-weight: 600;
                flex-grow: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #222;
              }
            }
            .preview-description {
              font-size: 14px;
              font-weight: 400;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 3;
              -webkit-line-clamp: 3; /* 최대 줄 수 */
              -webkit-box-orient: vertical;
              color: #222;
            }
            .preview-url {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-weight: 600;
              .preview-url-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .search-result-content-footer {
      width: 100%;
      height: 200px;
      display: flex;
      padding: 0 16px 24px 16px;
      justify-content: center;
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 10;
      background-color: transparent;
      align-items: flex-end;
      .search-result-content-search-more {
        max-width: 900px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: var(--shadow);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        textarea {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
        }
      }
    }
  }
}

.relative {
  position: relative;
}

.download-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 8px;
  padding: 8px 0;
  .excel-download-btn {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-weight: 600;
  }
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex: 1;
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    outline: none;
  }
  span {
    cursor: pointer;
    b {
      color: var(--color-primary);
    }
  }
}

.citation-item {
  display: flex;
  gap: 6px;
  align-items: center;
}

.citation-accordion {
  border-radius: 4px;
}

.citation-accordion-header {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  gap: 8px;
}

.arrow {
  display: inline-block;
  transition: transform 0.3s ease;
  transform: rotate(90deg);

  &.expanded {
    transform: rotate(-90deg);
  }
}

.citation-accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  position: relative;

  &.expanded {
    max-height: 1500px;
    overflow: visible;
  }
}

.citation-spec-item {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  background-color: #f5f5f5;
  .preview-icon-wrapper {
    position: relative;
  }
}

.citation-spec-item:first-child {
  border-top: none;
}

.citation-item-title {
  font-size: 18px;
  font-weight: 600;
  padding: 8px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 8px;
  padding: 16px;
}
.citation-single-item {
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-item-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.excel-style-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  input {
    width: 100%;
    border: none;
    background: transparent;
    padding: 4px;

    &:focus {
      outline: none;
      background-color: #f0f8ff;
    }
  }

  input[type="number"] {
    text-align: right;
  }
}

.search-item-content-statement {
  margin-top: 20px;
  width: 100%;

  .statement-section-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 20px 0 10px;
    padding-left: 10px;
    border-left: 4px solid #1890ff;
  }

  // 첫 번째 섹션 타이틀의 상단 마진 제거
  .statement-section-title:first-child {
    margin-top: 0;
  }
}

.statement-table {
  // 테이블 헤더 아이템 너비 조정
  .statement-table-header-item {
    &:nth-child(1) {
      width: 4%; // 품명
    }
    &:nth-child(2) {
      width: 18%; // 품명
    }
    &:nth-child(3) {
      width: 18%; // 규격
    }
    &:nth-child(4) {
      width: 10%; // 단위
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      width: 12.5%; // 합계, 재료비, 노무비, 경비
    }
  }

  // 테이블 셀 너비도 동일하게 조정
  .statement-table-cell {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: 12.5%;
    }
  }
}

.citation-preview-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.preview-icon-wrapper {
  position: relative;
  cursor: pointer;

  .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1000;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
    margin-bottom: 5px;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    }
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.fade-in-item {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-item-content-markdown {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 8px;
  .ant-design-icon {
    width: auto !important;
    height: auto !important;
    font-size: 16px !important;
    margin-top: 4px;
  }
  p {
    margin-bottom: 0;
  }
}

.feedback-container {
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .feedback-text {
    color: #666;
    font-size: 14px;
  }

  .feedback-buttons {
    display: flex;
    gap: 8px;

    .feedback-button {
      background: none;
      border: 1px solid #d9d9d9;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        border-color: #1890ff;
        color: #1890ff;
      }

      &.active {
        background: #1890ff;
        border-color: #1890ff;
        color: white;
      }
    }
  }
}

.citation-download-button-wrapper {
  display: flex;
  flex-direction: row;
  button {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
  }
  button:disabled {
    background-color: #e9e9e9;
    color: #666;
    cursor: not-allowed;
  }
}

.citation-download-button-wrapper {
  width: 360px;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  button {
    flex: 1;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
  }
  .reset-button {
    width: 80px;
    background-color: #e9e9e9;
    color: #666;
    border: none;
    cursor: pointer;
    outline: none;
  }
}
