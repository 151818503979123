.beginSafetyContainer {
  font-size: 12px;

  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 0 auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    input {
      border: hidden;
    }

    ::-webkit-input-placeholder {
      color: transparent;
    }

    .btn_container {
      display: none;
    }
    .save_container {
      display: none;
      color: white;
      span {
        display: none;
      }
    }
  }

  .title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .table_header {
    display: flex;
    width: 100%;
    text-align: center;
    border: 1px solid;

    .item_name {
      width: 25%;
      border-right: 1px solid;
      display: flex;
      justify-content: center;

      .item_num {
        width: 30%;
        align-self: center;
      }

      .item_num_name {
        width: 100%;
        align-self: center;
        word-break: keep-all;
        padding: 5px;
        text-align: left;
      }
    }

    .usage_date {
      width: 15%;
      border-right: 1px solid;

      .date_input {
        width: 100%;
      }

      .btn_container {
        width: 100%;
        height: 20px;

        .square_btn {
          width: 20px;
          height: 100%;
          line-height: 100%;
          background-color: var(--color-primary);
          color: white;
          font-weight: 600;
          min-width: 0;
          padding: 0;
        }
      }
    }

    .usage_plan_items {
      width: 45%;
      border-right: 1px solid;

      .plan_item_name_input {
        width: 100%;
      }

      .plan_item_num_input {
        width: 100%;
      }

      .plan_item_price_input {
        width: 100%;
      }
    }

    .usage_item_price {
      width: 15%;

      .item_price_input {
        width: 100%;
      }
    }
  }
  .save_container {
    display: flex;
    justify-content: center;
    width: 100%;

    .square_btn {
      width: 200px;
      height: 40px;
      font-size: 13px;
      margin-top: 20px;
      line-height: 100%;
      background-color: var(--color-primary);
      color: white;
      font-weight: 600;
      min-width: 0;
      padding: 0;
      span {
        color: white;
      }
    }
  }
  .input_box {
    height: 20px;
    padding: 1px;
  }

  .comment {
    padding-left: 5px;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }

  input {
    outline: none;
    height: 18px;
    text-align: center;
  }
}
