.wrapper {
  display: flex;
  padding: 36px;
  width: 465px;

  & > .icon {
    width: 100px;
  }

  & > .alerts {
    flex: 1;

    & > .title {
      font-size: 21px;
      font-weight: bold;
      width: 100%;
      text-align: left;
      // padding    : 30px;
    }

    & > .content {
      white-space: pre-line;
      width: 100%;
      // padding    : 30px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      padding-bottom:20px;
    }


    & > .warning {
      white-space: pre-line;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 30px;
      color: var(--color-error);
    }

    & > .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 24px;
      button{
        margin-left: 10px;
      }
      & > div {
        width: 130px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
        color: white;
        background-color: var(--color-primary);
      }

      & > div:first-child {
        background-color: var(--color-error) !important;
        margin-right: 10px;
      }
    }
  }
  .dis {
    background-color: var(--color-gray) !important;
    pointer-events: none;
  }
  .error-btn {
    background-color: var(--color-sub-error);
  }
}
