.fade-in {
  transition:
    opacity 0.3s,
    visibility 0.3s;
}
.fade-out {
  opacity: 0;
  visibility: hidden;
}
.tooltip-container {
  opacity: 1 !important;
  pointer-events: auto !important;
  position: relative;
  z-index: 1;
  &:hover {
    .tooltip-text {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  .tooltip-text {
    transition:
      opacity 0.3s,
      visibility 0.3s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 125%;
    font-size: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
    word-break: keep-all;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }
}
.search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgb(15 98 254) 0%, rgb(15 98 254 / 0%) 90%);
  }
  .search-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 16px;

    h1 {
      font-size: 30px;
      text-align: center;
      word-break: keep-all;
      b {
        color: var(--color-primary);
      }
    }
    .search-category {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
      .search-category-item {
        width: 180px;
        font-size: 16px;
        text-align: center;
        word-break: keep-all;
        padding: 8px 16px;
        background-color: #fff;
        border-radius: 12px;
        gap: 8px;
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
        box-shadow: var(--shadow);
        &.active {
          background-color: var(--color-primary);
          color: #fff;
        }
        .ant-design-icon {
          height: 16px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .search-category-item-text {
          flex: 1;
        }
      }
    }
  }
}

.relative {
  position: relative;
}

.search-result-aside {
  z-index: 100;
  position: fixed;
  width: 240px;
  background-color: #fff;
  box-shadow: var(--shadow);
  margin-top: 50px;
  left: -240px;
  transition: left 0.3s;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 48px;
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
  }
  .search-result-aside-content-history-list {
    max-height: 350px;
    overflow-y: scroll;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .aside-flag {
    left: 100%;
    top: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    gap: 8px;
    padding: 12px;
    cursor: pointer;
    transition: left 0.3s;
    border-radius: 8px;
  }
  .search-result-aside-content-history {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      background-color: #f5f5f5;
    }
    .search-result-aside-content-name {
      width: 100%;
      font-size: 14px;
      padding: 4px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .search-result-aside-content-date {
      font-size: 12px;
      color: #666;
    }
  }
  .search-result-aside-content-item {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    .search-result-aside-content-title {
      width: 100%;
      font-size: 16px;
      padding: 8px 0;
    }
  }
  .search-result-aside-content-item-primary {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    padding: 16px;
    cursor: pointer;
    transition:
      background-color 0.3s,
      color 0.3s;
    &:hover {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
  .search-result-aside-content-date {
    display: block;
    font-size: 12px;
    color: #999;
    margin-bottom: 4px;
  }

  .search-result-aside-content-name {
    display: block;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .search-result-aside-content-type {
    display: inline-block;
    font-size: 12px;
    color: var(--color-primary);
    border-radius: 4px;
    margin-top: 4px;
  }
}
