.search-input-wrapper {
  max-width: 900px;
  width: 100%;
  padding-top: 8px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  position: relative;

  .search-input-loading {
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 24px 16px;
    background-color: rgba($color: #fff, $alpha: 5);
    height: 108px;
    font-size: 16px;
  }
  textarea {
    width: 100%;
    min-height: 100px;
    border: none;
    outline: none;
    padding: 16px;
    font-size: 16px;
    overflow: hidden;
    resize: none;
  }
  .search-input-footer {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-top: 1px solid var(--color-border);
    .search-input-footer-left {
      display: flex;
      align-items: center;
      gap: 8px;
      button {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: normal;
      }
    }
    .search-input-footer-right {
      display: flex;
      align-items: center;
      gap: 8px;
      button {
        font-weight: normal;
      }
      .btn-pro {
        background-color: #ffc107;
        color: white;
      }
    }
  }
}

.rounded-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input-footer-left-token-count {
  font-size: 12px;
  color: var(--color-primary);
}
