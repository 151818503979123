.communityPageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.communityPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.categoryTitle {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

.controlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.searchContainer {
  flex-grow: 1;
  margin-right: 20px;
}

.searchInput {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 20px;
  padding-left: 15px;
  transition: all 0.3s ease;

  &:hover, &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  input {
    font-size: 16px;
  }
}

.searchIcon {
  color: #bfbfbf;
}

.sortContainer {
  display: flex;
  align-items: center;
}

.sortButton {
  height: 32px;
  line-height: 30px;
  padding: 0 15px;
  border-radius: 16px;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.postList {
  margin-bottom: 30px;
}

.writeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.writeButton {
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  span {
    vertical-align: text-top !important;
  }
}

.writeModal {
  :global(.ant-modal-mask) {
    z-index: 1150 !important;
  }

  :global(.ant-modal-wrap) {
    z-index: 1200 !important;
  }

  :global(.ant-modal-content) {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.searchContainer {
  margin-bottom: 20px;
}

.searchInput {
  width: 300px;
}

.globalLoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  flex-direction: column;
  gap: 16px;
}
