.category_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .category_box {
    padding: 10px 20px;
    text-align: center;
    background-color: #e9ecef;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-right: 10px;
    span {
      font-weight: normal;
      color: #495057;
      font-size: 14px;
      user-select: none;
      font-weight: bold;
    }
    &.selected {
      background-color: var(--color-primary);
      span {
        color: #fff;
      }
    }
    .tag {
      padding: 0 10px;
      top: -10px;
      right: -10px;
      position: absolute;
      background-color: var(--color-primary);
      color: #fff;
      border-radius: 10px;
      min-width: 40px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
}
