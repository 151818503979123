.notice-detail-wrapper {
  width: 100%;
  min-height: 100%;
  & > div {
    width: 970px;
    height: 100%;
  }

  .notice-title {
    width: 100%;
    height: 150px;
    line-height: 150px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  .notice-detail-content {
    width: 100%;
    & > .notice-detail-title {
      border-bottom: 1px solid lightgrey;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      & > span:first-child {
        font-weight: bold;
        font-size: 18px;
      }
    }
    & > .notice-detail-content {
      width: 100%;
      img {
        max-width: 100%;
      }
    }
    & > .notice_content_wrapper {
      white-space: pre-wrap;
      border-bottom: 1px solid lightgray;
      width: 100%;
      padding: 30px 30px;
      ul li {
        margin-left: 20px;
      }
      img {
        max-width: 100%;
      }
      h1 {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .bottom_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin-bottom: 50px;
  }
  .to_list_button {
    width: 100px;
    height: 30px;
    cursor: pointer;
    font-size: 15px;
    color: rgb(68, 132, 255);
    text-align: center;
    line-height: 2em;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(68, 132, 255);
    font-weight: bold;
  }
}
