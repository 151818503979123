.excelWrapper {
  width: 100%;
  min-height: 100vh;
}

.workArea {
  min-height: 100vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.dropArea {
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #33333b;
  min-height: 100%;
  position: absolute;
  z-index: 9999;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
  text-align: center;
  color: #fff;
  padding: 230px;
}
.uploadArea {
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  .titleWrapper {
    padding: 6px 24px 30px;
    .title {
      color: #33333b;
      text-align: center;
      font-family: Noto Sans KR;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 123.81% */
    }

    .subtitle {
      margin: 8px auto 0;
      color: #47474f;
      text-align: center;
      font-family: Noto Sans KR;
      font-size: 22px;
      font-style: normal;
      font-weight: 350;
      line-height: 32px; /* 145.455% */
    }
  }

  .selectFile {
    min-height: 80px;
    width: 330px;
    border-radius: 12px;
    color: #fff;
    line-height: 28px;
    padding: 24px 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;
    margin-bottom: 12px;
    font-size: 24px;
    background: var(--color-primary);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.14);
  }

  .dragInfo {
    color: #47474f;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px; /* 142.857% */
    .dragText {
      color: var(--color-sub-error);
    }
  }
}
.uploadedArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .leftArea {
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .explain {
      margin-bottom: 20px;
    }
  }
  .excelFileWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .excelCard {
      margin: 4px;
      margin-bottom: 50px;
      width: 254px;
      max-height: 324px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0);
      background: #fdfdfd;
      border-radius: 8px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
      .excelName {
        height: 28px;
        width: 100%;
        position: absolute;
        display: flex;
        border-radius: 7px;
        top: 0;
        .excelTitle {
          text-align: center;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          line-height: 16px;
          padding: 6px 0px;
        }
        .delete {
          padding: 3px;
          width: 24px;
          height: 24px;
          -ms-flex: 0 0 24px;
          flex: 0 0 24px;
          text-align: center;
          background: rgba(0, 0, 0, 0.1);
          background: #ebebf4;
          margin-left: 4px;
          z-index: 1030;
          border-radius: 100%;
          cursor: pointer;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
          justify-content: center;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
          display: none;
          &:hover {
            background: var(--color-sub-error);
            path {
              color: #fff;
            }
          }
        }
      }
      .sheetNames {
        width: 100%;
        margin-top: 30px;
        overflow-y: auto;
        padding-left: 25px;
        .sheetRow {
          display: flex;
          align-items: center;
          align-content: center;
          cursor: pointer;
          .hiddenSheet {
            color: #999;
          }
          .sheetName {
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      //hover
      &:hover {
        border: 1px solid var(--color-primary);

        .excelName {
          background: var(--color-primary);
          .excelTitle {
            color: #fff;
          }
          .checkbox {
            path {
              color: #fff;
            }
          }
          .delete {
            display: flex;
          }
        }
      }
    }
  }
  .pannel {
    width: 400px;
    min-height: 100vh;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    .title {
      padding: 12px;
      font-weight: 500;
      font-size: 28px;
      line-height: 30px;
      color: #33333b;
      margin-bottom: 0;
      text-align: center;
      padding: 21px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;
      background-color: #fff;
    }
    .addFileWrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .addFile {
        min-height: 50px;
        width: 100%;
        border-radius: 12px;
        color: #33333b;
        line-height: 28px;
        padding: 12px 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 12px;
        font-size: 20px;
        background: #fff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.14);
        // 굵은 border 추가
        border: 2px solid #33333b;
      }
    }
    .options {
      .option {
        padding: 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        .optionTitle {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #33333b;
          margin-bottom: 0;
        }
        .optionContent {
          margin-top: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #33333b;
          margin-bottom: 0;
          .optionItem {
            margin-top: 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .optionName {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #33333b;
              margin-bottom: 0;
            }
            .optionValue {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #33333b;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .executeWrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      .execute {
        min-height: 50px;
        width: 100%;
        border-radius: 12px;
        color: #fff;
        line-height: 28px;
        padding: 12px 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 12px;
        font-size: 20px;
        background: var(--color-primary);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.14);
      }
    }
  }
}
.tooltip_container {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
.tooltip_text {
  visibility: hidden;
  font-size: 12px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  width: auto;
  white-space: normal; /* 텍스트가 최대 세줄까지 나오도록 변경 */
  left: auto;
  right: 200%;
  margin-right: -100px;
}

.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}
