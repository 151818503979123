.contain {
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  .contents_wrapper {
    width: 1200px;
    .dashboard {
      border: 1px solid red;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      display: flex;
      justify-content: center;
    }
    .lecture_contain {
      border: 1px solid blue;
      width: 100%;
      flex-direction: column;
    }
    .lecture_search {
        border: 1px solid darkcyan;
      flex-direction: column;
      .lecture_title {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .lecture_filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
      }
    }
    .lecture_list{
        border: 1px solid red;
        list-style: none;
        display: flex;
        align-items: center;
        ul{
            display: flex;
            li{
                display: list-item;
            }
        }
      }
  }
}
