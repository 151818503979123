.datePickerWrapper {
    width: 100%;
    height: 252px;
    border: 1px solid var(--color-background);
}

.year-month-container {
    width: 100%;
    padding: 5px;
    display: flex;
    height: 31px;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-background);
}

.year-month-container > div:first-child {
    font-weight: bold;
    cursor: pointer;
}

.year-month-container > div:last-child > span {
    font-weight: bold;
    cursor: pointer;
}

.week {
    width: 100%;
    height: calc((100% - 31px) / 6);
    display: flex;
}

.years {
    width: 100%;
    height: calc(100% - 31px);
}

.year {
    width: 50%;
    height: 20%;
    float: left;
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
}
.year:hover {
    background-color: var(--color-sub);
}

.month {
    width: calc(100% / 3);
    height: 25%;
    float: left;
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
}

.month:hover {
    background-color: var(--color-sub);
}

.day {
    width: calc(100% / 7);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.day > div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.day-differ {
    & > div {
        color: var(--color-gray5);
    }
}

.past {
    background-color: var(--color-gray6);
}

.select {
    background-color: var(--color-primary);
    color: white;
}

