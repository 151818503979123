.dialog_container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  .dialog_btn {
    margin: 0 auto;
    width: 285px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 6px;
    background-color: var(--color-primary);
    font-weight: bold;
    cursor: pointer;
    height: 38px;
    border: none;
    margin-top: 20px;
  }
}
.signup-container {
  width: 721px;
  height: 100%;
  button {
    color: var(--color-video-primary) !important;
  }
  .signup-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  .user_info_container {
    width: 721px;
    margin: 0 auto;
    display: flex;
    padding: 50px 80px;
    justify-content: center;
    flex-direction: column;
    border: 1px solid lightgrey;
    .form_item {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 10px 0;
      & > :first-child {
        width: 130px;
        & > span {
          font-size: 15px;
        }
      }
      input {
        padding-left: 10px;
      }
      .checkbox_container {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .multi_select_container {
          height: 30px;
          min-width: 130px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          & > .checkbox {
            width: 24px;
            height: 24px;
            margin-right: 1px;
          }
          input {
            width: 24px;
            height: 24px;
          }
          & > .text {
            margin-right: 20px;
          }
        }
      }
      button {
        width: 92px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 13px;
        background-color: rgb(255, 255, 255);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(236, 236, 236);
        cursor: pointer;
        font-size: 15px;
        color: var(--color-primary);
        margin-left: 12px;
        &:hover {
          background-color: var(--color-background);
        }
      }
      .dk-btn {
        width: 130px !important;
      }
    }
    .submit-button {
      margin: 0 auto;
      width: 285px;
      font-size: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      border-radius: 6px;
      background-color: var(--color-video-primary) !important;
      color: white !important;
      font-weight: bold;
      cursor: pointer;
      height: 38px;
      border: none;
      margin-top: 20px;
    }
    .submit-button:disabled {
      cursor: default;
      opacity: 0.7;
    }
    .menu-logo {
      width: 235px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 60px;
      margin: 0 auto;
    }
    .terms-container {
      width: 100%;
      border-radius: 3px;
      border: 1px solid var(--color-primary);
      padding: 3px;
      background-color: white;
      white-space: pre-line;
      height: 200px;
      overflow-y: scroll;
    }
    .terms-control {
      width: 100%;
      margin-top: 3px;
      background-color: white;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .terms_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      .entire_agree {
        font-size: 15px;
        color: rgb(127, 127, 127);
      }
      .necessary {
        color: rgb(192, 57, 43);
      }
      .under_line {
        cursor: pointer;
        text-decoration-line: underline;
      }
      span {
        padding: 0;
        padding-right: 3px;
        margin-bottom: 1px;
      }
    }
    .invalid-msg {
      width: 100%;
      margin-top: 0.25rem;
      color: var(--color-error);
      font-size: 85%;
      position: absolute;
      top: 28px;
      left: 130px;
    }
    .is-invalid {
      width: 100%;
      border: 1px solid var(--color-error) !important;
    }
    .dk-input {
      width: 285px;
      height: 36px;
      border: 1px solid rgb(224, 224, 224);
      border-radius: 3px;
      outline: none !important;
      box-sizing: border-box;
      transition: 0.25s;
    }
    .dk-input:focus {
      border: 1px solid var(--color-primary);
      outline: none !important;
      box-sizing: border-box;
    }
    .star {
      color: var(--color-error);
    }
    .birth-wrapper {
      width: 285px;
      height: 36px;
      display: flex;
      justify-content: space-between;
      & > div {
        width: calc(100%);
        height: 100%;
        border: 1px solid rgb(224, 224, 224);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }
}
